var sukoaUtils = {

    footerPositionInit: function (footer, wrap) {
        if (!sukoaUtils.isMobile()) {

            $(window).load(function () {
                sukoaUtils.footerPositionResize(footer, wrap);
            });
            $(window).resize(function () {
                sukoaUtils.footerPositionResize(footer, wrap);
            });
        }
    },

    // TODO: rewrite to check if window height is greater than wrap+footer height
    footerPositionResize: function (footer, wrap) {
        if (!sukoaUtils.getBaseSetting("isEditMode")) {
            var footerHeight = footer.outerHeight(true);
            var wrapHeight = wrap.outerHeight(true);
            var windowHeight = $(window).height();
            if (windowHeight > wrapHeight+footerHeight) {
                footer.addClass('fixed');
            } else {
                footer.removeClass('fixed');
            }
        }
    },

    isMobile : function(){
        return window.innerWidth < 641;
    },


    // set taget on links
    openExternalLinksInNewWindow: function () {
        $("#content-area a[href^='http://']").attr("target", "_blank");
    },

    // check if canvas is supported
    isCanvasSupported: function () {
        var elem = document.createElement('canvas');
        return !!(elem.getContext && elem.getContext('2d'));
    },

    // append js script to head
    appendScriptToHead: function (src) {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = src;
        $("head").append(s);
    },

    formErrorHandling : function(form) {
        var f = jQuery(form);
        f.find('.error').removeClass('error');
        var result = true;
        f.find(":text.required, input:file.required, input:radio.required, textarea.required").each(function () {
            if (jQuery(this).val().trim() === "") {
                handleValidationError(this, $(this).attr("data-errormessage"));
                result = false;
            }
        });
        f.find("select.required").each(function () {
            if (jQuery(this).prop("selectedIndex") === 0) {
                handleValidationError(this, $(this).attr("data-errormessage"));
                result = false;
            }
        });
        f.find(":text.email").each(function () {
            var c = jQuery(this).val().trim();
            if (c !== "" && !validateEmail(c)) {
                handleValidationError(this, $(this).attr("data-errormessage"));
                result = false;
            }
        });
        f.find('.optiongroup').each(function(){
            if(jQuery(this).parent().hasClass("required")){
                if ( jQuery(this).find('input:radio,input:checkbox').length>0 && jQuery(this).find('input:radio:checked,input:checkbox:checked,.custom.radio.checked').length==0  ) {
                    handleValidationError(this, $(this).attr("data-errormessage"));
                    result = false
                }
            }
        });

        if(result) {
            var commentWrapperOuter = $("#comment-wrapper-outer");
            commentWrapperOuter.on("submit","form",function(e){
                var theForm = $(this);
                var url = theForm.attr("action");
                if(url===""){url = sukoaUtils.getCurrentURL(true);}
                $.ajax({
                    type: "POST",
                    url: url,
                    data: theForm.serialize(),
                    success: function(data) {
                        theForm.slideUp();
                        $("#comment-sent-confirm").show();
                    }
                });
                e.preventDefault();
            });

        }
        function handleValidationError(obj, message) {
            jQuery(obj).addClass("error");
            if ( jQuery('.error').length<=1) {
                alert(getJsDecodedString(message));
            }
            return false;
        }
        function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
        /* prevent &Uuml;mlauts, etc in js alerts.*/
        function getJsDecodedString(raw) {
            var div = document.createElement('div');
            div.innerHTML = raw;
            var decoded = div.firstChild.nodeValue;
            return decoded;
        }

        return result;
    },

    // get hash param
    urlGetHashParam: function (url) {
        if(!url){
            url = window.location.hash;
        }
        var results = new RegExp('[\#]([^&#]*)').exec(url);
        if (results == null) {
            return null;
        } else {
            return results[1] || 0;
        }
    },

    // get param from current url
    getParameterByName: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    // get a certain part of a url
    urlGetSection: function (url, index) {
        var explodedUrl = url.split("/");
        var section = "";

        if (index == "last") {
            section = explodedUrl[explodedUrl.length - 1];
            if (section == "") {
                section = explodedUrl[explodedUrl.length - 2];
            }
            section = section.replace(".html", "");
        } else {
            section = explodedUrl[index];
        }
        return section;
    },

    // get current url without param
    getCurrentURL : function(){
        var url = window.location.href;
        var i = url.indexOf("?");
        if(i==-1){
            i = url.indexOf("#");
        }
        if(i>-1){
            url = url.substring(0,i);
        }
        return url;
    },
    
    // shuffle array
    shuffleArray: function (o) {
        for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
        return o;
    },

    // check if mobile based on screen
    checkIsMobileScreen: function () {
        return $(window).width() <= 853;
    },

    checkIsTouchDevice: function () {
        if (Modernizr && Modernizr.touch) {
            return true;
        }
        return false;
    },

    // set base settings vars/objects for other scripts to use
    baseSettings: {},

    addBaseSetting: function (k, v) {
        sukoaUtils.baseSettings[k] = v;
    },

    getBaseSetting: function (k, d) {
        if (sukoaUtils.baseSettings[k]) {
            d = sukoaUtils.baseSettings[k];
        }
        return d;
    },

    // Browser Reject
    oldBrowserFeedback: ['', '', '', '', '', '', ''],

    setOldBrowserFeedback: function (f0, f1, f2, f3, f4, f5, f6, f7) {
        sukoaUtils.oldBrowserFeedback[0] = f0;
        sukoaUtils.oldBrowserFeedback[1] = f1;
        sukoaUtils.oldBrowserFeedback[2] = f2;
        sukoaUtils.oldBrowserFeedback[3] = f3;
        sukoaUtils.oldBrowserFeedback[4] = f4;
        sukoaUtils.oldBrowserFeedback[5] = f5;
        sukoaUtils.oldBrowserFeedback[6] = f6;
        sukoaUtils.oldBrowserFeedback[7] = f7;
    },

    getOldBrowserFeedback: function (i, d) {
        if (sukoaUtils.oldBrowserFeedback[i]) {
            return sukoaUtils.oldBrowserFeedback[i];
        } else {
            return d;
        }
    },

    rejectOldBrowserFeedback: function () {
        if ($.reject) {
            $.reject({
                reject: {
                    all: false,
                    msie5: true,
                    msie6: true,
                    msie7: true,
                    msie8: true,
                    firefox1: true,
                    firefox2: true,
                    firefox3: true,
                    firefox4: true,
                    firefox5: true,
                    firefox6: true,
                    firefox7: true,
                    firefox8: true,
                    firefox9: true,
                    safari1: true,
                    safari2: true,
                    safari3: true,
                    chrome1: true,
                    chrome2: true,
                    chrome3: true,
                    chrome4: true,
                    chrome5: true,
                    chrome6: true,
                    chrome7: true,
                    chrome8: true,
                    chrome9: true,
                    chrome10: true,
                    chrome11: true,
                    chrome12: true,
                    chrome13: true,
                    chrome14: true,
                    chrome15: true,
                    opera1: true,
                    opera2: true,
                    opera3: true,
                    opera4: true,
                    opera5: true,
                    opera6: true,
                    opera7: true,
                    opera8: true,
                    opera9: true,
                    opera10: true,
                    opera11: true,
                    opera12: true,
                    opera13: true,
                    opera14: true,
                    opera15: true,
                    opera16: true,
                    opera17: true,
                    opera18: true,
                    opera19: true,
                    opera20: true
                },
                display: ["chrome", "firefox", "safari", "msie"],
                browserInfo: {
                    firefox: {
                        text: "Firefox",
                        url: "http://www.mozilla.com/firefox/"
                    },
                    safari: {
                        text: "Safari",
                        url: "http://www.apple.com/safari/download/"
                    },
                    chrome: {
                        text: "Chrome",
                        url: "http://www.google.com/chrome/"
                    },
                    msie: {
                        text: "Internet Explorer",
                        url: "http://www.microsoft.com/windows/internet-explorer/"
                    }
                },
                imagePath: sukoaUtils.getBaseSetting('baseHandle', '') + '/docroot/jreject/img/',
                overlayBgColor: "#293237",
                header: sukoaUtils.getOldBrowserFeedback(0, "Wussten Sie, dass Ihr Internet Browser nicht mehr aktuell ist?"),
                paragraph1: sukoaUtils.getOldBrowserFeedback(1, "Ihr Browser erfüllt leider nicht die Kriterien, mit denen diese Webseite genutzt werden kann. Bitte laden sie kostenlos einen aktuellen Browser. Gute Gründe für eine Browser-Aktualisierung sind: nimmt nur wenige Minuten Zeit in Anspruch, optimiert viele Darstellungen, verkürzt Ladezeiten und dient Ihrer Sicherheit beim Surfen im Internet."),
                paragraph2: sukoaUtils.getOldBrowserFeedback(2, "Bitte wählen Sie einen der folgenden Browser:"),
                close: true,
                closeMessage: sukoaUtils.getOldBrowserFeedback(3, "Wenn Sie das Fenster schliessen, sehen sie eine reduzierte Website, die nicht vollständig funktioniert."),
                closeLink: sukoaUtils.getOldBrowserFeedback(4, "Fenster schliessen"),
                closeESC: false,
                closeCookie: false
            });
        }
    },

    bindFancyboxFn: function (options) {
        var bodyObject = $('body');

        //bind fancybox
        bodyObject.on('click', '.fancybox,.lightbox', function (e) {
            e.preventDefault();
            var data = $(this).data('image');
            if (data) {
                data = data.split(',');
            }
            if (data) {
                $.fancybox.open(data, options);
            } else {
                $(this).fancybox(options);
            }
        });
    },

    bindCacheKillerLinkFn: function () {
        // cache killer for link element
        $(".kclink").each(function () {
            var $l = $(this).attr("href");
            if ($l != undefined) {
                if ($l.indexOf("?") == -1) {
                    $l += "?";
                } else {
                    $l += "&";
                }
                $l += "kc=" + new Date().getTime();
                $(this).attr("href", $l)
            }
        });
    },

    bindCacheKillerImgsFn: function () {
        // cache killer for src (img, script ...) element
        $(".kcsrc").each(function () {
            var $l = $(this).attr("src");
            if ($l != undefined) {
                if ($l.indexOf("?") == -1) {
                    $l += "?";
                } else {
                    $l += "&";
                }
                $l += "kc=" + new Date().getTime();
                $(this).attr("src", $l)
            }
        });
    },

    getParam: function (url, name) {

        var results = new RegExp('[\#]' + name + '_([^&#]*)').exec(url);
        if (results == null) {
            results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
        }
        if (results == null) {
            return null;
        } else {
            return results[1] || 0;
        }

    },

    getURLWithoutParameters: function (url) {
        if (url.indexOf("?") > -1) {
            url = url.substring(0, url.indexOf("?"));
        }
        if (url.indexOf("#") > -1) {
            url = url.substring(0, url.indexOf("#"));
        }
        return url;
    },

    magnoliaEditModeLink: function () {
        $('body').on("click", ".magnolia-edit-link", function () {
            var t = $(this);
            var u = t.data("href");
            var cu = window.top.location.href;
            cu = cu.substring(0, cu.indexOf('detail;') + "detail;".length);
            window.top.location.href = cu + u + ":edit";
        });
    },

    equalizeEntries: function (items, includeMargins) {

        var maxh = 0;
        items.each(function () {
            var e = $(this);
            var h = e.height();
            if(includeMargins){
                h = e.outerHeight(true);
            }
            if (h > maxh) {
                maxh = h;
            }
        });
        items.css("height", maxh + "px");
    },

    equalizeRows: function () {
        var rows = $(".equalize");
        rows.each(function () {
            var maxh = 0;
            var e = $(this);
            var entries = e.find('.equalize-entry');
            entries.css("height", "auto");
            if(!sukoaUtils.isMobile()){
                entries.each(function(){
                    var t = $(this);
                    var h = t.outerHeight(true);
                    if (h > maxh) {
                        maxh = h;
                    }
                });
                entries.css("height", maxh + "px");
            }
        });

    }
};

// sukoa simple form
var sukoaForm = {

    init : function(){
        sukoaForm.bind();
    },

    bind : function(){
        sukoaForm.bindConditionalShow();
    },

    bindConditionalShow : function(){
        // bind conditional functionality

        // hide fields that need to shown based on conditional
        var conditionalShowCheckboxes = $('[data-conditional-show]');
        if(!sukoaUtils.getBaseSetting('isEditMode')){
            if(conditionalShowCheckboxes.length > 1){
                conditionalShowCheckboxes.each(function() {
                    var t = $(this);
                    var formElements = $(t.attr('data-conditional-show'));
                    formElements.hide().each(function(index){
                        var formElement = $(formElements[index]);
                        var optionGroup = formElement.find('.optiongroup');
                        var fields = $(formElements[index]).find('input, textarea, select');
                        var fieldId = fields.attr('id');

                        if(optionGroup.length > 0){
                            fieldId = formElement.attr('id').replace('fe_', '');
                        }
                        fields.attr("name", fieldId+"_off");
                    });

                });
            }
        }

        // bind conditional listener
        conditionalShowCheckboxes.on('change', function(){
            var t = $(this);
            var fieldname = t.attr('data-conditional-show-field-name');
            var group = t.attr('data-conditional-show-group');
            var formElements = $(t.attr('data-conditional-show'));
            var fieldIds = t.attr('data-conditional-show').split(',');
            // field for mail output
            var mailField = $('#'+fieldname);
            var name = fieldname;

            if(t.prop('checked')){
                mailField.attr("name",name);
                formElements.show().each(function(index){
                    var formElement = $(formElements[index]);
                    var optionGroup = formElement.find('.optiongroup');
                    var fields = $(formElements[index]).find('input, textarea, select');
                    var fieldId = fields.attr('id');

                    if(optionGroup.length > 0){
                        fieldId = formElement.attr('id').replace('fe_', '');

                    }
                    fields.attr("name", fieldId);

                });
            }else{

                var otherConditionalsShow = t.closest('form').find('[data-conditional-show]');

                for(var i2 = 0; i2 < fieldIds.length; i2++ ) {
                    var fieldId = fieldIds[i2];
                    var tempField = $(fieldId);
                    var tempFieldInput = tempField.find('input, textarea, select');
                    var tempFieldInputId = tempFieldInput.attr('id');
                    var showField = false;


                    otherConditionalsShow.each(function(index){
                        var otherConditionalsInput = $(otherConditionalsShow[index]);
                        // checks if interated input is the currently clicked one
                        if(!(otherConditionalsInput[0] === t[0])){
                            var otherConditionalsInputFields = otherConditionalsInput.attr('data-conditional-show');
                            if(otherConditionalsInput.prop('checked') && otherConditionalsInputFields.indexOf(fieldId) > -1){
                                showField = true;
                            }
                        }
                    });

                    if(!showField){
                        tempField.hide();
                        tempFieldInput.attr("name", tempFieldInputId+"_off");
                        mailField.attr("name",name+"_off");
                    }
                }
            }
        });
    }
};


export {sukoaUtils, sukoaForm};
