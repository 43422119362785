const tools = {
    dataLayerHandler: null,

    initDataLayer(dlh) {
        this.dataLayerHandler = dlh;
        this.dataLayerHandler.init();
    },

    updateDataLayer() {
        if(this.dataLayerHandler) {
            this.dataLayerHandler.update();
        }
    },

    deactivateDataLayer() {
        if(this.dataLayerHandler) {
            this.dataLayerHandler.deactivate();
        }
    },

    executeDataLayerBy(handlerName, dataLayerEvent, event=null) {
        if(this.dataLayerHandler) {
            this.dataLayerHandler.executeBy(handlerName, dataLayerEvent, event);
        }
    },

    hasParameter(name) {
        return window.location.search.indexOf('?' + name) !== -1 || window.location.search.indexOf('&' + name) !== -1;
    },

    ajxGet(url, fallback=null){
        let req = new XMLHttpRequest();
        req.onreadystatechange = function() {
            if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                if(fallback) {
                    fallback(req);
                }
            }
        };
        req.open('GET', url, true);
        req.send(null);
    },

    each(list, fn) {
        if(list && fn) {
            for(let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    getCurrentURL : function(excludeParams){
        var url = window.location.href;
        if(excludeParams){
            var i = url.indexOf("?");
            if(i>-1){
                url = url.substring(0,i);
            }
            var j = url.indexOf("#");
            if(j>-1){
                url = url.substring(0,j);
            }
        }
        return url;
    },

    getParameterByName: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    isEditMode() {return document.body.classList.contains('editmode')},

    detectSafari() {
        return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    },

    detectIE() {
        var ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    },

    runDiag() {
        let sukoaBlue = '#03e1ff';
        let targetSelector = '.gallery';
        let targets = document.querySelectorAll('.gallery');
        if (targets.length > 0) {
            tools.each(targets, target => {
                target.style.outline = '2px solid ' +sukoaBlue;
            })
        }
    },

    scrollTo: function (element, tempo) {
        $('html, body').animate({
            scrollTop: element.offset().top
        }, tempo);
    },

    scrollTo: function (element, tempo, offset = 0) {
        $('html, body').animate({
            scrollTop: element.offset().top - offset
        }, tempo);
    }
};

module.exports = tools;
