import Form from './form';
import Swiper from 'swiper';
import diogFilter from './filter.js';
import diogBlog from './blog.js'
import ShoppingCart from "./ShoppingCart";
import SelectItemDL from "./datalayer/views/SelectItemDL";
import ViewItemDL from "./datalayer/views/ViewItemDL";
import $ from "jquery";

require('./externalLibs');

var SC = require('soundcloud');

const Floatl = require('./vendor/floatl.min');
const sukoaUtils = require('./vendor/sukoaUtils').sukoaUtils;
const sukoaForm = require('./vendor/sukoaUtils').sukoaForm;
const tools = require('./tools');
const dataLayerHandler = require("./datalayer/dataLayerHandler").default;


const sukoaDIOG = {
    manualStateChange: true,
    gettingNewDetail: false,
    detail: null,
    lastBookClicked: null,
    lastBookClickedTitle: null,
    previousHeight: null,
    ajaxRequestDetail: null,
    regularUrl: window.location.pathname,

    init: function () {
        //general
        sukoaDIOG.dataLayer();
        sukoaDIOG.gatherBaseSettings();
        sukoaDIOG.gatherLabels();
        tools.runDiag();
        sukoaDIOG.foundationInit();
        sukoaDIOG.equalizeInit();
        sukoaDIOG.fancyboxInit();
        sukoaDIOG.collapsedContentInit();
        sukoaDIOG.resizeIconsOnTabChange();
        sukoaDIOG.userSplashFeedback();
        sukoaDIOG.formStylingInit();
        sukoaDIOG.initForms();
        sukoaForm.init();
        sukoaDIOG.tabsInit();

        //buy list click listener
        sukoaDIOG.initBuyListOverlyClickListener();
        sukoaDIOG.initBuyListClickListener();

        // mobile
        navigation.init();

        // filter
        // sukoaDIOG.filterFormInit();
        diogFilter.init();

        // home
        sukoaDIOG.teaserInit();
        sukoaDIOG.patchInit();
        sukoaDIOG.newbooksInit();
        sukoaDIOG.pressReleaseInit();
        sukoaDIOG.titleDetailInit();
        sukoaDIOG.eventListBind();

        sukoaDIOG.bindHighlightFromSearch();

        //authorDetail
        sukoaDIOG.authorDetailInit();

        //pressMaterial
        sukoaDIOG.pressMaterialInit();

        //reviewCopies
        sukoaDIOG.reviewCopiesInit();

        //footer
        sukoaDIOG.footerInit();

        //list
        sukoaDIOG.listGetDetail();

        //search
        sukoaDIOG.searchInit();

        //fade in loaded images
        sukoaDIOG.fadeInLoadedEntries();

        sukoaDIOG.resizeIcons();
        $(window).resize(function () {
            sukoaDIOG.resizeIcons();
        });
        sukoaDIOG.loginInit();

        sukoaDIOG.newsletterFormInit();


        //blog
        // sukoaD…IOG.blog.init();
        diogBlog.init();

        if(sukoaUtils.getBaseSetting('isEditMode')) {
            let ready = new Event('editmodeready');
            document.dispatchEvent(ready);
        }

        new Swiper('.swiper-container-otherbooks', {
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            slidesPerView: 6,
        });

        sukoaDIOG.authorTools();

        sukoaDIOG.initShoppingCart();

        sukoaDIOG.initSearch();
    },


    dataLayer() {
        tools.initDataLayer(dataLayerHandler);
    },

    initBuyListOverlyClickListener: function () {
        $(".buy-button").click(function (event) {
            let fancyboxSettings = {
                autoDimensions: true,
                type: 'html',
                autoSize: true,
                autoScale: false,
                closeBtn: true,
                autoCenter: true,
                transitionIn: 'none',
                transitionOut: 'none',
                scrolling: 'auto',
                wrapCSS: 'panel-fancybox-buylist',
                padding: 0,
                helpers: {
                    overlay: {
                        locked: false
                    }
                },
                afterShow: function () {
                    sukoaDIOG.initBuyListClickListener();
                }
            };
            let overlayId = $(this).attr("data-overlayid");
            let html = "<div id='buy-link-list-grid'>"+$("#"+overlayId).html()+"</div>";
            $.fancybox.open(html,fancyboxSettings);
        });

        if (document.location.search.substr(1).indexOf("b=true") >= 0) {
            $(".buy-button").click();
        }
    },

    initBuyListClickListener: function () {
        $("#buy-link-list-grid a").click(function (event) {
            handleOutboundLinkClicks(event);
        });

        function handleOutboundLinkClicks(event) {
            if(typeof ga !== 'undefined') {
                ga('send', 'event', {
                    eventCategory: 'Kaufen Links',
                    eventAction: 'click',
                    eventLabel: event.target.getAttribute('data-eventlabel')
                });
            }
        }
    },

    gatherBaseSettings() {
        if (baseSettings) {
            sukoaUtils.baseSettings = baseSettings;
        }
    },

    gatherLabels() {
        if (labels) {
            sukoaUtils.labels = labels;
        }
    },

    //general
    foundationInit: function () {
        $(document).foundation({
            tab: {
                callback: function (tab) {
                    sukoaDIOG.resizeIcons();
                }
            }
        });

        var mobileBlogSidebartab = $('#tabs-blog-sidebar_mobile');

        $(mobileBlogSidebartab).tabs({
            collapsible: true,
            active: false,
            classes: null
        });

        $(mobileBlogSidebartab).removeClass("ui-widget ui-widget-content");

    },


    equalizeInit: function () {

        sukoaUtils.equalizeRows();
        $(window).resize(function () {
            sukoaUtils.equalizeRows();
        });
        $(window).on('load', function () {
            sukoaUtils.equalizeRows();
        });
    },

    initForms: function () {
        let $forms = $('form');
        if ($forms.length > 0) {
            $forms.each(function () {
                new Form($(this))
            })
        }
    },

    formStylingInit: function () {
        // When using jQuery, you can pass in a jQuery object, doesn't support select
        let floatingInputs = $('.floatl');
        floatingInputs.each(function () {
            new Floatl($(this));
        });

        // hack for select, technically this solution could make the above obsolete.
        $(function () {
            let onClass = "floatl--focused";
            let showClass = "floatl--active";
            let formatElement = $(".floatl-select select");
            formatElement
                .bind("checkval", function () {
                    let label = $(this).prev("label");
                    if (this.value !== "")
                        label.closest('.floatl-select').addClass(showClass);
                    else
                        label.closest('.floatl-select').removeClass(showClass);
                }).on("keyup", function () {
                $(this).trigger("checkval");
            }).on("focus", function () {
                $(this).closest('.floatl-select').addClass(onClass);
            }).on("blur", function () {
                $(this).closest('.floatl-select').removeClass(onClass);
            }).trigger("checkval");

            formatElement.on("change", function () {
                let $this = $(this);

                if ($this.val() === "")
                    $this.addClass("watermark");
                else
                    $this.removeClass("watermark");
                $this.trigger("checkval");
            }).change();
        });

    },

    fancyboxInit: function () {
        let fancyboxSettings = {
            autoDimensions: false,
            type: 'image',
            autoSize: false,
            autoScale: false,
            closeBtn: true,
            autoCenter: true,
            width: 984,
            transitionIn: 'none',
            transitionOut: 'none',
            scrolling: 'auto',
            wrapCSS: 'panel-fancybox',
            padding: 0,
            helpers: {
                overlay: {
                    locked: false
                }
            }
        };

        sukoaUtils.bindFancyboxFn(fancyboxSettings);
    },

    userSplashFeedback: function () {
        let userSplashFeedback = $("#user-splash-feedback");
        if (userSplashFeedback.length > 0) {
            let fancyboxSettings = {
                autoDimensions: false,
                autoSize: false,
                autoScale: false,
                closeBtn: true,
                autoCenter: true,
                padding: 20,
                width: 984,
                height: "auto",
                transitionIn: 'none',
                transitionOut: 'none',
                scrolling: 'auto',
                wrapCSS: 'panel-fancybox',
                helpers: {
                    overlay: {
                        locked: false
                    }
                },
                afterClose: function () {
                    let currentUrl = window.location.href;
                    currentUrl += "&showsplash=false";
                    window.location.href = currentUrl;
                }
            };

            $.fancybox(userSplashFeedback.show(), fancyboxSettings)
        }

    },

    collapsedContentInit: function () {
        $('body').on('click', "[data-collapsed]", function () {
            let t = $(this);
            let parent = t.parent();
            parent.find(".collapsed-content").slideToggle(100);
        });
    },

    // Tabs
    resizeIconsOnTabChange: function () {
        $('body').on('click', ".tabs-title", function () {
            sukoaDIOG.resizeIcons();
        });
    },


    // footer
    footerInit: function () {
        sukoaDIOG.footerCollapse();
    },

    footerCollapse: function () {
        if (!$('body').hasClass('home')) {
            let footer = $("#footer");
            let footerContent = $(".footer-content");

            footer.mouseenter(function () {
                footerContent.stop().slideDown(100);
            }).mouseleave(function () {
                footerContent.stop().slideUp(100);
            });

            footer.on('click', function () {
                footerContent.stop().slideToggle(100);
            });
        }
    },

    //filterForm
    gettingNewPage: false,
    ajaxRequest: null,
    nextPageParams: null, // parameters
    cleanUrl: null, // baseUrl for history.js plugin


    fadeInLoadedEntries: function ($object, callback) {
        function fadeInBooks($images, callback) {

            $images.each(function (index) {
                // if image loaded for first time
                let icon = $(this);
                let image = icon.find("img:not(.background-image)");
                let text = icon.find('.text');

                let showText = false;
                if (text.length > 0) {
                    showText = true;
                }

                // normal load
                if (image.length > 0 && !image[0].complete) {
                    // bind on load listener
                    let imageLoaded = false;

                    image.on('load', function () {
                        imageLoaded = true;
                        if (showText) {
                            text.fadeIn(500);
                        }
                        icon.find(".image").fadeIn(500, function () {
                            if (callback) {
                                callback(icon);
                            }
                        });

                        icon.removeClass("loading");
                    });

                    // if image not loaded, still loads author name
                    setTimeout(() => {
                        if(!imageLoaded && showText) {
                            text.fadeIn(500);
                        }
                    }, 800);

                    // cache load
                } else {
                    if (showText) {
                        text.fadeIn(500);
                    }
                    icon.find(".image").fadeIn(500, function () {

                        if (callback) {
                            callback(icon);
                        }

                    });

                    icon.removeClass("loading");
                }

            });
        }

        if ($object && $object.length > 0) {
            fadeInBooks($object, callback);
        } else {
            fadeInBooks($('.icon, .detail'), callback);
        }

        sukoaDIOG.resizeIcons();
    },


    displayTitleDetailsInline: function ($detail, data, heightConstant) {

        // function to animate the detail wrapper and set the global height constant
        function animateHeight($detail, newHeight, previousHeight, speed) {
            $detail.animate({
                'height': newHeight
            }, speed, function () {
                $detail.css({"visibility": "visible"});
                $detail.css({"height": ""});
            });
            sukoaDIOG[previousHeight] = newHeight;
        }

        $detail.css({"visibility": "hidden"});
        // get the data content
        let detailData = $($(data).find('.title-detail-wrapper').html());
        if($(data).find('#title-detail-display').length>0){
            detailData = $(data).find('#title-detail-display .title-detail-wrapper').html(); //todo: check
        }

        // get the detail row
        let detailRow = $detail.find('.detail-row');

        // append new data content to the previos detail
        detailRow.html(detailData);

        // get the image in the new detail content
        let icon = detailRow.find('.detail.title-icon');

        sukoaDIOG.fadeInLoadedEntries(icon);
        let newHeight = detailRow.outerHeight(true);
        animateHeight($detail, newHeight, heightConstant, 100);

        $detail.removeClass('loading');

        // append «kaufen» overlay
        sukoaDIOG.initBuyListOverlyClickListener();
        sukoaDIOG.authorTools();

        //DATALAYER///
        const $title = $detail.find('.title-detail');
        if($title) {
            tools.executeDataLayerBy("views", new ViewItemDL($title[0]));
            // tools.executeDataLayerBy("views", new SelectItemDL($title[0]));
        }
        //DATALAYER///

        return newHeight;
    },

    imageRatio: 1.6,

    resizeIcons: function () {
        setTimeout(() => {
            let titles = $('.icon:not(.align-top):visible, .detail:not(.align-top):visible');

            titles.each(function () {
            let t = $(this);
            let ratio = t.attr("data-format-ratio");
            let imageRatio = sukoaDIOG.imageRatio;
            let image = t.find('.image');
            if (image.length === 0) {
                image = t.find('iframe');
            }
            let imageWidth = image.width();

            if (ratio) {
                imageRatio = parseFloat(ratio);
            }
            t.find('.image-wrapper').height(imageWidth * imageRatio);
            if (t.hasClass('book-special')) {
                t.find('.title-image').css({'max-height': imageWidth * imageRatio - 30 + "px"});
            }

        })}, 10);
    },

    historyPushState: function (url, newTitle) {

        if (!sukoaUtils.getBaseSetting("isEditMode")) {
            sukoaDIOG.manualStateChange = false;
            History.pushState(null, newTitle, url);
            if (typeof ga === 'function') {
                ga('send', 'pageview', url);
            }
        }
    },

    //home
    teaserInit: function () {

        if (!sukoaUtils.getBaseSetting("isEditMode")) {
            let interval = $('[data-swiper-interval]').attr('data-swiper-interval') || 5000;
            let options = {
                pagination: {
                    el: '.swiper-pagination-teaser',
                    type: 'bullets',
                    clickable: true,
                },
                on: {
                    init: function () {
                        let thisSwiper = this;
                        sukoaDIOG.fadeInLoadedEntries($('#teaser .swiper-slide .icon'), function (icon) {
                            sukoaDIOG.teaserResizeDynamicTeasers(icon.closest('.swiper-slide'));
                            // for mobile
                            sukoaDIOG.teaserResizeSlideOnChange(thisSwiper);
                        });

                        $(window).on('load', function () {
                            sukoaDIOG.teaserResizeSlideOnChange(thisSwiper);
                        });
                    },

                    slideChangeTransitionEnd: function () {
                        sukoaDIOG.teaserResizeSlideOnChange(this);
                    }
                }
            };

            if ($('#teaser .swiper-slide').length > 1) {
                options.loop = true;
                if (interval) {
                    options.autoplay = { delay:interval, disableOnInteraction: false};
                }
                let mySwiper = new Swiper('.swiper-container-teaser', options);
            } else {
                sukoaDIOG.fadeInLoadedEntries($('#teaser .swiper-slide .icon'), function () {
                    sukoaDIOG.teaserResizeDynamicTeasers();
                });

            }
        } else {
            sukoaDIOG.fadeInLoadedEntries($('#teaser .swiper-slide .icon'), function () {
                sukoaDIOG.teaserResizeDynamicTeasers();
            });
        }
        $(window).resize(function () {
            let swiperObj = $('.swiper-container-teaser');
            if (swiperObj.length > 0) {
                sukoaDIOG.teaserResizeSlideOnChange(swiperObj[0].swiper);
            }
            if (!(sukoaUtils.isMobile())) {
                sukoaDIOG.teaserResizeDynamicTeasers();
            }
        });
    },

    teaserResizeSlideOnChange: function (swiper) {
        if (swiper) {
            if (sukoaUtils.isMobile()) {
                swiper.$wrapperEl[0].style.height = 'auto';
                let activeSlide = swiper.slides[swiper.activeIndex];
                let slideHeight = $(activeSlide).height();
                swiper.$wrapperEl[0].style.height = (slideHeight + 30) + 'px';
            } else {
                swiper.$wrapperEl[0].style.height = 'auto';
            }
        }

    },

    teaserResizeDynamicTeasers: function ($object) {
        let slides = $('#teaser').find('.swiper-slide');
        if ($object) {
            slides = $object;
        }

        slides.each(function () {
            let t = $(this);
            let dynamicTeaser = t.find('.home-teaser__dynamic');
            if (dynamicTeaser.length > 0) {
                if (!(sukoaUtils.isMobile())) {
                    let title = dynamicTeaser.find('.title-icon');
                    let titlewidth = title.width();
                    dynamicTeaser.find('.home-teaser__text').css({'padding-left': titlewidth + "px"}).fadeIn();
                } else {
                    dynamicTeaser.find('.home-teaser__text').css({'padding-left' : ""}).fadeIn();
                }
            }
        });
    },


    patchInit: function () {
        let persistNewPosition = ($mybox, $relToEl, h, topOffset) => {
            let ts = new Date().getTime();
            let l = percentSize($mybox.css("left"), $relToEl.css("width"));
            let t = $mybox.css("top");
            t = t.replace(/[^-0-9]+/g, '');
            if(t < 0 ) {
                // t = 100;
            }

            let $wrapper = $('#wrapper');
            if($wrapper && t > $wrapper.outerHeight(true)) {
                t = 100;
            }
            t = (t * 1) - topOffset;
            $.get("?handle=" + h + "&patchTop=" + t + "&patchLeft=" + l + "&ts=" + ts);
        };

        let percentSize = (l, pa) => {
            l = l.replace(/[^-0-9.,]+/g, '');
            l = l * 1;
            pa = pa.replace(/[^-0-9]+/g, '');
            return ((l * 100) / pa).toFixed(2);
        };
        let $relElt = $('#wrapper');
        $('.patch').each(function () {
            let t = $(this);
            if (t.find(".dragger").length > 0) {
                let dragIcon = t.find('.drag-icon');
                let handle = t.attr('data-handle');
                if (dragIcon.length > 0 && handle) {
                    t.draggable({
                        handle: '.drag-icon',
                        containment: "#contentwrap",
                        cursor: 'move',
                        zIndex: 2700,
                        stop: function () {
                            $(this).css("left", percentSize($(this).css("left"), $relElt.css("width")) + "%");
                            persistNewPosition(t, $relElt, handle, 0);
                        }
                    });
                }
            }
        });
    },

    pressReleaseInit: function () {
        let mySwiper = new Swiper('.swiper-container-home-press-release-list', {
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next-home-press-release-list',
                prevEl: '.swiper-button-prev-home-press-release-list'
            },
            slidesPerView: 6,
            centeredSlides: false,
            paginationClickable: true
        });

    },

    newbooksInit: function () {
        document.querySelectorAll(".swiper-container-newbooks").forEach(el => {
            let breakPointConfig = {
                640:{slidesPerView: el.dataset.slidesPerViewSmall },
                1024:{slidesPerView: el.dataset.slidesPerViewMedium }
            }

            let mySwiper = new Swiper(el, {
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next-newbooks',
                    prevEl: '.swiper-button-prev-newbooks'
                },
                simulateTouch: !sukoaUtils.getBaseSetting("isEditMode"),
                centeredSlides: false,
                pagination: {
                    clickable: true
                },
                paginationClickable: true,
                spaceBetween: 10,

                slidesPerView: el.dataset.slidesPerView,
                breakpoints: breakPointConfig,
            });

            handleDisableSwiperButtons(el);

            mySwiper.on('resize', () => {
                handleDisableSwiperButtons(el);
            })
        })

        let newbooks = $(".home-newbooks");
        // init loaded
        if (newbooks.length > 0) {
            sukoaDIOG.fadeInLoadedEntries();
        }

        function handleDisableSwiperButtons(swiperContainer) {
            let countDisabledButtons = swiperContainer.parentElement.querySelectorAll(".swiper-button-disabled").length
            if (countDisabledButtons >= 2) {
                swiperContainer.parentElement.classList.add('swiper-buttons-disabled')
            } else {
                swiperContainer.parentElement.classList.remove('swiper-buttons-disabled')
            }
        }

    },

    // functionality for linking text from search to position of the text in each page
    bindHighlightFromSearch: function () {
        let searchType = sukoaUtils.getParam(window.location.href, "search_type");
        let searchDetail = sukoaUtils.getParam(window.location.href, "search_detail");

        if (searchType && searchDetail) {
            let detail = $('[data-search-uuid="' + searchDetail + '"]');
            if (detail.length > 0) {

                if (searchType === "Text_Presse") {
                    let swiperSlide = detail.closest('.swiper-slide');
                    let slideIndex = swiperSlide.index();
                    let swiperContainer = detail.closest('.swiper-container');
                    if (swiperContainer.length > 0) {
                        let swiperInstance = swiperContainer.data('swiper');
                        swiperInstance.slideTo(slideIndex, 1000, function (swiper) {
                            sukoaDIOG.resizeSwiper(swiper);
                        });
                    }
                } else if (searchType === "Award" || searchType === "Movie_Adaption") {
                    let wrapperClass = ".awards";
                    if (searchType === "Movie_Adaption") {
                        wrapperClass = ".movie-adaptions"
                    }

                    if (detail.is(":hidden")) {
                        detail.closest(wrapperClass).find(".expand-interface").trigger('click', function () {
                            $("html, body").animate({scrollTop: detail.offset().top}, 300);
                        });
                    }
                }
                detail.addClass('search-highlight');

                setTimeout(function () {
                    detail.removeClass('search-highlight');
                }, 2000);
            }
        }

    },

    // titleDetail
    titleDetailInit: function () {
        sukoaDIOG.initEmbedWidgets();
        sukoaDIOG.titleDetailPresseSwiperInit();
        sukoaDIOG.titleDetailPresseShowHide();
    },

    resizeSwiper: function (swiper) {
        let activeSlide = swiper.slides[swiper.activeIndex];
        let slideHeight = $(activeSlide).find('.presse-entry').outerHeight(true);
        let swiperContainer = $(swiper.$wrapperEl);

        let paginationHeight = swiperContainer.find('.swiper-button-next-presse').outerHeight(true);
        swiperContainer.height(slideHeight + paginationHeight);
        swiper.update();
    },

    // presse swiper init and show all functionality
    titleDetailPresseSwiperInit: function () {

        if (!sukoaUtils.getBaseSetting("isEditMode")) {
            let slides = $('.swiper-container-presse').find('.swiper-slide');

            if (slides.length > 1) {
                let mySwiper = new Swiper('.swiper-container-presse', {
                    loop: false,
                    navigation: {
                        nextEl: '.swiper-button-next-presse',
                        prevEl: '.swiper-button-prev-presse',
                    },
                    spaceBetween: 5,
                    on: {
                        init: function () {
                            sukoaDIOG.resizeSwiper(this);
                        },
                        onTransitionStart: function () {
                            sukoaDIOG.resizeSwiper(this);
                        }
                    }
                });
            }
        }
    },


    titleDetailPresseShowHide: function () {
        $('body').on('click', '[data-expand]', function () {

            let t = $(this);
            let type = t.data('expand');

            let container = t.closest('.presse');
            let presseType = container.data('presse-type');
            let swiperContainer = container.find('.swiper-container');
            let presseList = container.find('.presse-list');

            // interface interaction
            container.find('.expand-icon').hide();
            let state = "open";
            if (type == "open") {
                state = "close";
            }

            container.find('[data-expand="' + state + '"]').show();

            // calculate height
            let height = 350;
            if (presseType && presseType == "diogenesTitle") {
                let detail = $(".detail:visible");
                let presseListHeight = presseList.outerHeight(true);
                if (detail.length > 0) {
                    let image = detail.find('.image');
                    height = image.height() - 15;

                    let authorLink = container.parent().parent().find('.author-link');
                    let presseInterface = t.parent();
                    if (authorLink.length > 0) {
                        let authorLinkHeight = authorLink.outerHeight(true);
                        height = height - authorLinkHeight;
                        presseListHeight = presseListHeight + authorLinkHeight;
                    }
                    if (presseInterface.length > 0) {
                        let presseInterfaceHeight = presseInterface.outerHeight(true);
                        height = height - presseInterfaceHeight;
                        presseListHeight = presseListHeight + presseInterfaceHeight;
                    }
                }

                if (presseListHeight > height) {
                    presseList.height(height);
                }
            } else {
                if (presseList.height() > height) {
                    presseList.height(height);
                }
            }

            presseList.toggle();
            swiperContainer.toggle();
            swiperContainer[0].swiper.update();
        });
    },

    initEmbedWidgets: function () {
        // bind main functionality
        if (!sukoaUtils.getBaseSetting("isEditMode")) {
            sukoaDIOG.initWidgetConsent();
            sukoaDIOG.initWidgetHandling();


        }
    },

    // eventList
    eventListBind: function () {

        $('body').on('click', '[data-collapse]', function () {

            $('.home-event-list-summary').removeClass('active');
            let t = $(this);

            function showAndHideInterface(contentIsVisible, t) {
                let open = t.find('[data-collapse-open]');
                let close = t.find('[data-collapse-close]');
                if (contentIsVisible) {
                    open.hide();
                    close.show();
                } else {
                    open.show();
                    close.hide();
                }
            }

            // animate
            let type = t.attr('data-collapse');

            let toggleString = t.attr('data-collapse-toggle');
            let toggle = true;
            if (toggleString && toggleString == "false") {
                toggle = false;
            }

            let allowMultipleString = t.attr('data-collapse-allow-multiple');
            let allowMultiple = false;
            if (allowMultipleString && allowMultipleString == "true") {
                allowMultiple = true;
            }

            // has interface?


            let parent = t.parent();
            let collapsedContent = parent.find('.collapse-content').first();

            let lazyLoadComponent = $(collapsedContent).find('.lazyload[data-src]');
            if (lazyLoadComponent.length > 0) {
                lazyLoadComponent.attr('src', lazyLoadComponent.data('src'));
                lazyLoadComponent.removeData('src');
                lazyLoadComponent.removeAttr('data-src');
                lazyLoadComponent.removeClass('lazyload');
            }

            if (!collapsedContent.is(':hidden')) {

                if (toggle) {
                    if (type === "showHide") {
                        collapsedContent.hide();
                        showAndHideInterface(false, t);
                    } else {
                        collapsedContent.slideUp();
                        showAndHideInterface(false, t);
                    }
                    parent.removeClass('active');
                }

            } else {
                let collapsibles = $('[data-collapse]').parent().removeClass('active').find('.collapse-content:visible');
                if (type === "showHide") {
                    if (!allowMultiple) {
                        collapsibles.hide();
                    }
                    collapsedContent.show();
                } else {
                    if (!allowMultiple) {
                        collapsibles.slideUp();
                    }
                    collapsedContent.slideDown();
                }
                showAndHideInterface(true, t);

                parent.addClass('active');
            }
            sukoaDIOG.resizeIcons();
        });
    },

    isGettingAuthorTitleDetail: false,
    previousAuthorTitleDetailHeight: null,
    authorTitleDetail: null,
    lastAuthorTitleClicked: null,
    lastAuthorTitleDetailClicked: null,
    isGettingAuthorTitleDetailOtherFormat: false,

    authorDetailInit: function () {
        if ($(".author-detail-wrapper").length > 0) {
            sukoaDIOG.authorDetailGetTitleDetail();
            sukoaDIOG.authorDetailGetOtherFormat();
            sukoaDIOG.authorDetailTitleList();
            sukoaUtils.equalizeEntries($('.icon.authorDetail .text'), true);
        }
    },

    authorDetailGetTitleDetail: function () {
        let authorBooklist = $('.author-titlelist');
        authorBooklist.on('click', '[data-detaillink]', function (e) {
            e.preventDefault();

            //if(e.target) return; // only continue if the target itself has been clicked
            let t = $(this);
            let queryUrl = t.find('.detaillink').attr('href');
            let regularUrl = sukoaUtils.getURLWithoutParameters(sukoaDIOG.regularUrl);

            if (sukoaDIOG.lastAuthorTitleDetailClicked === this && sukoaDIOG.isGettingAuthorTitleDetail === false) {
                sukoaDIOG.authorTitleDetail.slideToggle(function () {
                    if ($(this).is(':hidden')) {
                        sukoaDIOG.historyPushState(regularUrl);
                    } else {
                        sukoaDIOG.historyPushState(queryUrl, sukoaDIOG.lastAuthorTitleClicked);
                    }
                });
            } else {

                let detail = $('<div class="detail-row-wrapper loading large-30 column"><div class="detail-row row"></div></div>');
                let insertObject = $('.author-detail');
                let previousDetail = $('.detail-row-wrapper');
                let height = 470;
                if (sukoaDIOG.previousAuthorTitleDetailHeight != null) {
                    height = sukoaDIOG.previousAuthorTitleDetailHeight;
                }

                // if detail already is shown
                if (previousDetail.length > 0) {
                    detail.hide().height(height).prependTo(insertObject);
                    if (previousDetail.is(':hidden')) {
                        previousDetail.remove();
                        detail.slideDown(200);
                    } else {
                        previousDetail.remove();
                        detail.fadeIn(200);
                    }
                    tools.scrollTo(detail, 100);
                    // if detail doesn't exist
                } else {
                    detail.hide().height(height).prependTo(insertObject);
                    detail.slideDown(200);
                    tools.scrollTo(detail, 100);
                }

                let separator = (queryUrl.indexOf("?") > -1 ? "&" : "?");

                if (sukoaDIOG.isGettingAuthorTitleDetail === false) {
                    sukoaDIOG.isGettingAuthorTitleDetail = true;
                    $.ajax({
                        url: queryUrl + separator + "ajax=true&context=authorDetailTitleListDetail",
                        method: "POST",
                        success: function (data) {
                            sukoaDIOG.displayTitleDetailsInline(detail, data, "previousAuthorTitleDetailHeight");
                            sukoaDIOG.authorTitleDetail = detail;
                            sukoaDIOG.titleDetailPresseSwiperInit();
                            let newTitle = $(data).filter('title').text();
                            //todo: url of book
                            sukoaDIOG.historyPushState(queryUrl, newTitle);
                            sukoaDIOG.isGettingAuthorTitleDetail = false;
                            sukoaDIOG.lastAuthorTitleClicked = newTitle;
                        }
                    });
                }
            }

            sukoaDIOG.lastAuthorTitleDetailClicked = this;

        });
    },

    authorDetailGetOtherFormat: function () {

        if ($('.author-detail-wrapper').length > 0) {
            $('body').on('click', '.format', function (e) {
                e.preventDefault();
                let t = $(this);
                let cleanUrl = $(this).attr('href');
                let separator = (cleanUrl.indexOf("?") > -1 ? "&" : "?");
                let queryUrl = cleanUrl + separator + "ajax=true&context=authorDetailTitleListDetail&isEditMode=" + sukoaUtils.getBaseSetting("isEditMode");
                let previousDetail = $('.detail-row-wrapper');

                if (previousDetail.length === 0) {
                    let detail = $('<div class="detail-row-wrapper loading large-30 column"><div class="detail-row row"></div></div>');
                    let insertObject = $('.author-detail');
                    let height = 470;
                    if (sukoaDIOG.previousAuthorTitleDetailHeight != null) {
                        height = sukoaDIOG.previousAuthorTitleDetailHeight;
                    }
                    detail.hide().height(height).prependTo(insertObject);
                    detail.slideDown(200);
                    tools.scrollTo(detail, 100);
                    previousDetail = detail;
                }

                if (sukoaDIOG.isGettingAuthorTitleDetailOtherFormat === false) {
                    sukoaDIOG.isGettingAuthorTitleDetailOtherFormat = true;
                    $.ajax({
                        url: queryUrl,
                        method: "POST",
                        success: function (data) {
                            sukoaDIOG.displayTitleDetailsInline(previousDetail, data, "previousAuthorTitleDetailHeight");
                            sukoaDIOG.titleDetailPresseSwiperInit();
                            let newTitle = $(data).filter('title').text();
                            sukoaDIOG.historyPushState(cleanUrl, newTitle);
                            sukoaDIOG.isGettingAuthorTitleDetailOtherFormat = false;
                        }
                    });
                }
            });
        }
    },

    authorDetailTitleList: function () {
        let authorBooklist = $('.author-titlelist');
        authorBooklist.on('click', '[data-view]', function (e) {
            let t = $(this);
            let type = t.data('view');
            if (type !== "") {
                let iconItems = authorBooklist.find('.author-titlelist-icons-content');
                let listItems = authorBooklist.find('.author-titlelist-list-content');

                if (type === "list") {
                    listItems.show();
                    iconItems.hide();

                } else {
                    iconItems.show();
                    listItems.hide();
                }
                $('[data-view]').removeClass('active');
                t.addClass('active');
                sukoaDIOG.resizeIcons();
            }
        });
    },


    listDetailIsGettingDetail: false,
    listDetail: null,
    listDetailLastClicked: null,

    listGetDetail: function () {
        let list = $('.list');
        if (list.length > 0) {

            sukoaDIOG.fadeInLoadedEntries();
            list.on('click', '[data-list-detaillink]', function (e) {
                e.preventDefault();
                let t = $(this);
                let type = t.attr("data-list-type");
                let queryUrl = t.attr('data-list-detaillink');

                if (sukoaDIOG.listDetailLastClicked === this && sukoaDIOG.listDetailIsGettingDetail === false) {


                } else {

                    if (sukoaDIOG.listDetailIsGettingDetail === false) {
                        sukoaDIOG.listDetailIsGettingDetail = true;
                        $.ajax({
                            url: queryUrl + "&ajax=true",
                            method: "POST",
                            success: function (data) {
                                let $data = $(data);
                                let listDetailContent = $data.find('.list-detail-content');
                                let listDetail = $('.list-detail');
                                let currentListDetailContent = listDetail.find('.list-detail-content');
                                currentListDetailContent.fadeOut(200).remove();
                                listDetailContent.hide().appendTo(listDetail).fadeIn(200);
                                sukoaDIOG.fadeInLoadedEntries();
                                $('.' + type + '-icon').removeClass('active');
                                t.closest('.' + type + '-icon').addClass('active');
                                let newTitle = $data.filter('title').text();
                                sukoaDIOG.historyPushState(queryUrl, newTitle);
                                sukoaDIOG.listDetailIsGettingDetail = false;

                            }
                        });
                    }
                }
                sukoaDIOG.listDetailLastClicked = this;

            });
        }
    },

    //data widget handling
    //Show overlay for play-inline widgets
    initWidgetConsent: function() {
        let marketingEnabled = false
        if (Cookiebot !== undefined && Cookiebot.consent !== undefined && Cookiebot.consent.marketing) {
            marketingEnabled = Cookiebot.consent.marketing
        }
        if (!marketingEnabled) {
            $('[data-widget-type]').each((idx, element) => {
                let widgetType = $(element).attr('data-widget-type');
                let playInline = $(element).attr('data-widget-play-inline');
                if (playInline === 'true') {
                    let image = $(element).find('.image');
                    if ($(image).find('a.cookieblocker').length < 1) {
                        let widgetUrl = $(element).attr('data-widget-url');

                        $(element).append(
                            '<div class="cookieblocker hidden" onclick="this.classList.remove(\'hidden\')"><span class="wrapper">\n    ' +
                            '<span>' + sukoaUtils.labels.cookieMissingConsentMarketing + '<br/><br/><a href="#" onclick="Cookiebot.renew()">'+ sukoaUtils.labels.openCookieSettings +'</a><br/><a href="'+widgetUrl+'" target="_blank" rel="noreferrer"> '+ sukoaUtils.labels.cookieMissingConsentLinkYoutube +'</a></span>\n  ' +
                            '</span></div>'
                        )
                    }
                }

            })
        }
    },

    initWidgetHandling: function () {
        // for soundcloud (app registered under thomas.john.walsh@gmail.com)
        SC.initialize({
            client_id: 'c8506afcb43cbb7c8c6c8742a732e903'
        });

        $('body').on('click', "[data-widget-type] a", function (e) {
            e.stopPropagation();
        });

        $('body').on('click', "[data-widget-type]", function (e) {

            e.preventDefault();
            // set to true so formFilter doesn't load new page when body has overflow:none;
            sukoaDIOG.gettingNewPage = true;


            let fancyboxSettings = {
                autoDimensions: false,
                autoSize: false,
                autoScale: false,
                closeBtn: true,
                autoCenter: true,
                width: 984,
                height: 550,
                transitionIn: 'none',
                transitionOut: 'none',
                scrolling: 'auto',
                wrapCSS: 'panel-fancybox',
                padding: 0,
                afterClose: function () {
                    sukoaDIOG.gettingNewPage = false;
                },
                helpers: {
                    overlay: {
                        locked: false
                    }
                }
            };

            let t = $(this);
            let widgetType = t.attr('data-widget-type');
            let widgetUrl = t.attr('data-widget-url');
            let widgetPlayInline = t.attr('data-widget-play-inline');
            let widgetImage = t.attr('data-widget-image');
            let html = $('<div class="fancybox-wrapper"></div>');
            let widgetId;

            let marketingEnabled = false
            if (Cookiebot !== undefined && Cookiebot.consent !== undefined && Cookiebot.consent.marketing) {
                marketingEnabled = Cookiebot.consent.marketing
            }

            if (widgetType === 'issuu') {

                if (marketingEnabled) {
                    widgetId = sukoaUtils.getParam(widgetUrl, 'e');
                    html.append('<div data-configid="' + widgetId + '" style="width:100%; height:100%;" class="issuuembed"></div><script type="text/javascript" src="//e.issuu.com/embed.js" async="true"></script>');
                } else {
                    fancyboxSettings.height = "auto";
                    fancyboxSettings.width = 400;
                    fancyboxSettings.padding = 20;
                    html.append("<div class=\"cookieblocker\">"+sukoaUtils.labels.cookieMissingConsentMarketing + "<br/><br/><a href='#' onclick='Cookiebot.renew()'>"+ sukoaUtils.labels.openCookieSettings +"</a><br/><a href='"+ widgetUrl + "' target='_blank' rel='noreferrer'>"+ sukoaUtils.labels.cookieMissingConsentLinkIssuu +"</a></div>")
                }
                $.fancybox(html, fancyboxSettings);

            } else if (widgetType === 'b2l') {
                fancyboxSettings.fitToView = true;
                // as per DG-397 set to full width / height
                fancyboxSettings.width = $(window).width();
                fancyboxSettings.height = $(window).height();
                fancyboxSettings.padding = 20;

                if (marketingEnabled) {
                    html.append('<iframe frameborder="0" width="' + fancyboxSettings.width + '" height="' + fancyboxSettings.height + '" style="width:100%; height:100%;" align="middle" src="' + widgetUrl + '&referurl=www.book2look.com"></iframe>');
                    //html.append('<iframe frameborder="0" width="100%" height="100%" style="1200px; height:800px" align="middle" src="'+ widgetUrl + '&referurl=www.book2look.com"></iframe>');
                } else {
                    fancyboxSettings.height = "auto";
                    fancyboxSettings.width = 400;
                    fancyboxSettings.padding = 20;
                    html.append("<div class=\"cookieblocker\">"+sukoaUtils.labels.cookieMissingConsentMarketing + "<br/><br/><a href='#' onclick='Cookiebot.renew()'>"+ sukoaUtils.labels.openCookieSettings +"</a><br/><a href='"+ widgetUrl + "' target='_blank' rel='noreferrer'>"+ sukoaUtils.labels.cookieMissingConsentLinkB2L +"</a></div>")
                    // html.append('<div style=\'position: absolute; top: 0; bottom: 0; left: 0; right: 0;display: flex;justify-content: center; align-items: center;\'>\n' +
                    //     '    <span>Keine Zustimmung <a href="'+ widgetUrl + '" target=\'_blank\'>Link?</a></span>\n' +
                    //     '</div>')
                }
                $.fancybox(html, fancyboxSettings);

            } else if (widgetType === 'soundcloud') {
                fancyboxSettings.height = 162;
                fancyboxSettings.width = 600;
                fancyboxSettings.padding = 20;

                if (marketingEnabled) {
                    SC.oEmbed(widgetUrl, {
                        color: 'ff5500',
                        auto_play:false,
                        show_comments:false,
                        show_user:false,
                        hide_related: false,
                        show_reposts: false,
                        show_playcount: false,
                        maxheight: 162,
                        maxwidth: 600

                    }).then(function(embed){
                        html.append(embed.html.replace("visual=true", "visual=false"));
                        $.fancybox(html, fancyboxSettings);
                    });
                } else {
                    // html.append("<div style=\'position: absolute; top: 0; bottom: 0; left: 0; right: 0;display: flex;justify-content: center; align-items: center;\'>\n    <span>Keine Zustimmung <a href=\'"+ widgetUrl + "\' target=\'_blank\'>Link?</a></span>\n</div>")
                    fancyboxSettings.height = 150;
                    fancyboxSettings.width = 400;
                    fancyboxSettings.padding = 20;
                    html.append("<div class=\"cookieblocker\">"+sukoaUtils.labels.cookieMissingConsentMarketing + "<br/><br/><a href='#' onclick='Cookiebot.renew()'>"+ sukoaUtils.labels.openCookieSettings +"</a><br/><a href='"+ widgetUrl + "' target='_blank' rel='noreferrer'>"+ sukoaUtils.labels.cookieMissingConsentLinkSoundcloud +"</a></div>")
                }

                $.fancybox(html, fancyboxSettings);

            } else if (widgetType === 'youtube') {

                let iconColor = t.attr('data-widget-youtube-color') || "white";
                widgetId = sukoaUtils.getParam(widgetUrl, 'v');
                let resourcePath = sukoaUtils.getBaseSetting("resourcePath");
                let image = 'https://img.youtube.com/vi/' + widgetId + '/hqdefault.jpg';
                if (widgetImage) {
                    image = widgetImage;
                }
                fancyboxSettings.closeBtn = false;

                let url = 'https://www.youtube.com/watch?v=' + widgetId;
                let overlay = ''
                if (marketingEnabled) {
                    overlay = '<div class="large trailer-overlay-wrapper" style="background-image:url(' + image + ');">' +
                        '<div class="trailer-overlay">' +
                        '<div class="trailer-overlay-play color-' + iconColor + '">' +
                        '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="143.2px" height="143.2px" viewBox="0 0 143.2 143.2" enable-background="new 0 0 143.2 143.2" xml:space="preserve">' +
                        '<g>' +
                        '<circle fill="none" stroke="#FFFFFF" stroke-width="3.032" cx="71.6" cy="71.6" r="70.1"/>' +
                        '<polygon fill="none" stroke="#FFFFFF" stroke-width="3.032" stroke-linecap="round" stroke-linejoin="round" points="117.9,71.6 42.8,27.1 42.8,116.1"/>' +
                        '</g>' +
                        '</svg>' +
                        '</div>' +
                        '<img class="trailer-overlay-thumb" />' +
                        '</div>' +
                        '</div>';
                } else {
                    fancyboxSettings.height = "auto";
                    fancyboxSettings.width = 400;
                    fancyboxSettings.padding = 20;
                    overlay = "<div class=\"cookieblocker\">"+sukoaUtils.labels.cookieMissingConsentMarketing + "<br/><br/><a href='#' onclick='Cookiebot.renew()'>"+ sukoaUtils.labels.openCookieSettings +"</a><br/><a href='"+ widgetUrl + "' target='_blank' rel='noreferrer'>"+ sukoaUtils.labels.cookieMissingConsentLinkYoutube +"</a></div>"
                    // overlay = '<div class="large trailer-overlay-wrapper" style="background-image:url(' + image + ');">\n    ' +
                    // '<div class="trailer-overlay">\n        Kein Konsent <a href="'+url +'" target="_blank">Zu Youtube</a>\n    ' +
                    // '</div>\n</div>';
                }

                let getData = $.getJSON('https://noembed.com/embed',
                    {format: 'json', url: url, maxwidth: 400}, function (data) {
                        // alert('Dimensions: ' + data.width + 'x' + data.height);
                        fancyboxSettings.width = data.width;
                        fancyboxSettings.height = data.height;
                    })
                    .always(function () {
                        let iframe = $('<iframe width="' + fancyboxSettings.width + '" height="' + fancyboxSettings.height + '" src="//www.youtube-nocookie.com/embed/' + widgetId + '/?rel=0&showinfo=0&autohide=1&autoplay=1" frameborder="0" allowfullscreen></iframe>');
                        html.append(overlay);

                        if (widgetPlayInline && widgetPlayInline === "true") {
                            if (marketingEnabled) {
                                t.addClass('inline-youtube-film').html(iframe);
                            }
                        } else {
                            $.fancybox(html, fancyboxSettings);

                            $('body').one('click', '.fancybox-wrapper .trailer-overlay-play', function () {
                                $(this).closest('.fancybox-wrapper').html(iframe);
                            });
                        }
                    });


            } else if (widgetType === 'assetDownload') {
                let ex = jQuery('a[href="' + widgetUrl + '"]');
                if (ex.length > 0) {
                    ex[0].click();
                }
            } else if (widgetType === 'download') {

                fancyboxSettings.height = "auto";
                fancyboxSettings.width = 400;
                fancyboxSettings.padding = 20;

                fancyboxSettings.closeBtn = true;
                fancyboxSettings.afterLoad = function () {
                };
                fancyboxSettings.afterClose = function () {
                    history.pushState("", document.title, window.location.pathname + window.location.search);
                };
                fancyboxSettings.beforeLoad = function () {
                    history.pushState(null, null, '#media-downloads');
                };

                let html = t.parent().parent().parent().find('.downloads-content');

                if (html.length < 1) {
                    html = $('.downloads-content');
                }
                $.fancybox(html, fancyboxSettings);

            } else if (widgetType === 'print') {

                window.print();

            }
        });
    },

    loginInit: function () {
        sukoaDIOG.loginBind();
    },

    loginBind: function () {

        // bind arrow to submit
        let arrowDiv = $("#registration-form .submit-arrow");
        let registrationForm = $("#registration-form");

        arrowDiv.on("click", function () {
            if (registrationForm.length > 0) {
                registrationForm.submit();
            }
        });

        registrationForm.on('submit', function () {

            //check for newsletter-consentToMail
            if (!sukoaDIOG.verifyConsentToMail()) {
                return false;
            }

            let loading = $(".login .fixed-loading-screen");
            if (registrationForm.parsley().isValid()) {
                loading.fadeIn();
            }
        });

        // make repeat-password required if password is set
        $('#register-user-wrapper.user-logged-in #register-password').on('change', function () {
            let t = $(this);
            let value = t.val();
            let passwordReenterInput = $("#register-user-wrapper").find("#register-renter-passsword");
            if (value != "") {
                passwordReenterInput.prop('required', true);
            } else {
                passwordReenterInput.prop('required', false);
                $('#registration-form').parsley().validate();
            }
        });

        //since campaignmonitor updates take time we need to refresh view after updating profile
        let isUpdatedProfileView = sukoaUtils.getParameterByName("userDataUpdateSucessfull") !== "";
        if (isUpdatedProfileView) {
            window.setTimeout(function () {
                $.ajax({
                    url: window.location + "&ck=" + new Date().getTime(),
                    method: "GET",
                    success: function (response) {
                        let data = $(response).find("#register-newsletter-settings").html();
                        $("#register-newsletter-settings").html(data).find(".loading").fadeOut();
                        sukoaDIOG.updateNewsletterConsent();
                    }
                });
            }, $("#register-newsletter-settings .loading").data('updateDelay'));
        }

        sukoaDIOG.updateNewsletterConsent();
    },

    newsletterFormInit: function () {
        $('#newsletter-form [name="newsletter"]').change(function (e) {
            if ($('#newsletter-form [name="newsletter"]:checkbox:checked').length === 0) {
                $('#newsletter-consentToMail').prop('checked', false);
                $('#newsletter-consentToMail + .error').removeClass('error');
                $('#newsletter-consentToTrack').prop('checked', false);
            }
        })
    },

    updateNewsletterConsent: function () {
        $('.newsletter-input').change(function (e) {
            if ($('input.newsletter-input:checkbox:checked').length === 0) {
                $('#newsletter-consentToMail').prop('checked', false);
                $('#newsletter-consentToMail + .error').removeClass('error');
                $('#newsletter-consentToTrack').prop('checked', false);
            }
        })
    },

    pressMaterialInit: function () {

        let body = $('body');

        body.on('click', '[data-download-press-material-form-close]', function (e) {
            $.fancybox.close();
        });


        function showFeedbackMessage($form, $message, responseObject) {
            $form.fadeOut(200, function () {
                if (responseObject.message) {
                    $message.find('#download-press-material__message-wrapper').html(responseObject.message);
                    $message.css({'display': 'table'});
                }
            });
        }

        body.on('submit', '.download-press-material__form', function (e) {
            e.preventDefault();
            e.stopPropagation()
            let t = $(this);
            let parent = t.parent();
            let message = parent.find('#download-press-material__message');
            let actionUrl = t.attr('action');
            let loader = parent.find('#download-press-material__loading');
            loader.fadeIn();
            $('#download-press-material__overlay').css({'overflow': 'hidden'});

            let renderId = this.dataset.recaptchaId
            grecaptcha.ready(function () {
                grecaptcha.execute(renderId, {action: 'submit_pressmaterial'}).then(function (token) {
                    // Add your logic to submit to your backend server here.
                    let input = document.createElement("input");
                    input.setAttribute("type", "hidden");
                    input.setAttribute("name", "grToken");
                    input.setAttribute("value", token);
                    t[0].appendChild(input);

                    $.ajax({
                        url: actionUrl,
                        method: "POST",
                        // data: t.find("input[type='hidden'], :input:not(:hidden)").serialize(),
                        data: t.find("input").serialize(),
                        dataType: "json",
                        success: function (json) {
                            loader.fadeOut(function () {
                                $('#download-press-material__overlay').css({'overflow': 'auto'});
                                input.remove()
                                if (json) {
                                    showFeedbackMessage(t, message, json);
                                } else {
                                    let response = {};
                                    response.message = "Ein Fehler is aufgetreten.";
                                    showFeedbackMessage(t, message, response);
                                }
                            });
                        }
                    });

                }).catch(function (error) {
                    console.warn("Error " + error)
                });
            });



        });

        body.on('click', '[data-download-press-material]', function (e) {
            let t = $(this);
            let type = t.attr("data-download-press-material");

            // only bind if attr is specific to download
            if (type == "#press-material") {
                e.preventDefault();
                let parent = t.parent();
                let html = parent.find('#download-press-material__overlay');
                let form = parent.find('.download-press-material__form');
                let message = parent.find('#download-press-material__message');
                let fancyboxSettings = {
                    autoDimensions: false,
                    autoSize: false,
                    autoScale: false,
                    closeBtn: true,
                    width: 984,
                    height: 'auto',
                    autoCenter: true,
                    transitionIn: 'none',
                    transitionOut: 'none',
                    scrolling: 'auto',
                    padding: [15, 0, 15, 0],
                    wrapCSS: 'panel-fancybox',
                    afterClose: function () {
                        form.hide();
                        message.hide();
                    },
                    afterShow: function () {
                        form.fadeIn();
                    },
                    helpers: {
                        overlay: {
                            locked: false
                        }
                    }
                };
                $.fancybox(html, fancyboxSettings);
            }
        });

    },

    reviewCopiesInit: function () {

        let reviewCopies = $('.review-copies');
        let basket = reviewCopies.find('.basket');
        if (reviewCopies.length > 0) {
            let body = $('body');

            body.on('click', '.media-item.print', function () {
                window.print();
            });

            body.on('click', '[data-review-copies]', function () {
                let t = $(this);
                let titleRecordid = t.attr('data-review-copies');
                let input = reviewCopies.find('[value="' + titleRecordid + '"]');
                let checkboxItem = input.closest('.checkbox-item');
                let checkboxItemInputs = checkboxItem.find('input');
                let titleItem = input.closest('.title-item');
                let hasCheckedInputs = false;


                titleItem.removeClass('title-item--active');
                input.prop("checked", !input.prop("checked"));

                checkboxItemInputs.each(function () {
                    let t = $(this);
                    if (t.is(":checked")) {
                        hasCheckedInputs = true;
                    }
                });

                if (!hasCheckedInputs) {
                    checkboxItem.removeClass('review-copies--active');
                    $('.review-copies__form').removeClass('hide');
                    $('.review-copies__address').addClass('hide');
                }

                let query = "";
                let command = "removefrombasket";
                if (input.is(':checked')) {
                    checkboxItem.addClass('review-copies--active');
                    titleItem.addClass('title-item--active');
                    command = "addtobasket";
                }
                if (command) {
                    query = "?cmd=" + command;
                    if (titleRecordid) {
                        query += "&rc_title=" + titleRecordid;
                    }
                }

                if (query) {
                    $.ajax({
                        url: query,
                        type: "get",
                        dataType: "html",
                        success: function (data) {
                            let reponse = $(data);
                            let newBasket = reponse.find('.basket');
                            basket.html(newBasket.html());
                        }
                    })
                }
            });

            body.on('click', '[data-review-copies-reset]', function () {
                let t = $(this);
                let checkboxItem = t.closest('.checkbox-item');
                let checkedInputs = checkboxItem.find('input:checked');
                let titleItems = checkboxItem.find('.title-item');
                if (checkedInputs.length > 0) {
                    titleItems.each(function () {
                        let t = $(this);
                        let input = t.find('input');
                        if (input.is(":checked")) {
                            let titleIcon = t.find('[data-review-copies]');
                            titleIcon.click();
                        }
                    });
                    checkboxItem.find('.collapse-content').slideUp();
                } else {
                    //titleItems.first().find("[data-review-copies]").click();
                    checkboxItem.find('.collapse-content').slideDown();
                }
            });

            body.on('click', '.review-copies__back', function () {
                $('.review-copies__form').toggleClass("hide");
                $('.review-copies__address').toggleClass('hide');
                $('.submit-arrow-wrapper').toggleClass('hide');
            });

            // review copies submit
            let form = $('.review-copies form');
            let loading = reviewCopies.find('.loading-screen');
            form.on('submit', function (e) {
                e.preventDefault();
                let serialized = form.serialize();
                let url = "?cmd=formsubmit";


                if ($('.review-copies__form').is(':visible')) {
                    $('.review-copies__form').toggleClass("hide");
                    $('.review-copies__address').toggleClass('hide');
                    $('.submit-arrow-wrapper').toggleClass('hide');
                } else {
                    let pForm = $(this).parsley();
                    let formValid = pForm.validate();
                    let consentToMail = sukoaDIOG.verifyConsentToMail();

                    if (formValid && consentToMail) {
                        $.ajax({
                            url: url,
                            data: serialized,
                            dataType: 'html',
                            beforeSend: function () {
                                loading.fadeIn();
                            },
                            success: function (data) {
                                let response = $(data);
                                let responseHtml = response.find('.review-copies').html();
                                let reviewCopies = $('.review-copies');
                                reviewCopies.html(responseHtml);
                            },
                            complete: function () {
                                loading.fadeOut();
                            }
                        })
                    }
                }
            })
        }
    },

    searchInit: function () {

        $('body').on('click', "[data-search-show-more]", function () {

            let t = $(this);
            let typeWrapper = t.closest('.type-wrapper');
            let groupsLeftOver = "";

            if (typeWrapper.length > 0) {
                let groups = typeWrapper.find(".type-wrapper__group:hidden");
                let groupToShow = groups.first();
                groupToShow.slideDown(function () {
                    sukoaDIOG.fadeInLoadedEntries(groupToShow);
                    let leftOverGroups = typeWrapper.find(".type-wrapper__group:hidden");
                    if (leftOverGroups.length === 0) {
                        t.slideUp();
                    }
                });
            }
        });
    },

    buyListHeightInit: function () {
        $("ul.buy-list").mouseenter(function () {
            let t = $(this);
            let h = t.find("ul").outerHeight();
            t.parent().css("min-height", h + "px");
        }).mouseleave(function () {
        });
    },


    gallery: function () {
        let galleries = $('.gallery');
        if (galleries.length > 0 && !sukoaUtils.getBaseSetting("isEditMode")) {
            galleries.each(function (index, element) {
                let t = $(this);
                t.addClass('gallery-swiper-' + index);
                t.find('.swiper-button-next').addClass('gallery-button-next-' + index);
                t.find('.swiper-button-prev').addClass('gallery-button-prev-' + index);
                t.find('.swiper-pagination').addClass('gallery-pagination-' + index);
                $('.gallery-swiper-' + index).swiper({
                    slidesPerView: 'auto',
                    pagination: {
                        el: '.gallery-pagination-' + index,
                        clickable: true,
                        type: 'bullets'
                    },
                    navigation: {
                        nextEl: '.gallery-button-next-' + index,
                        prevEl: '.gallery-button-prev-' + index
                    },
                    direction: 'horizontal',
                    spaceBetween: 12
                });
            });
        }
    },


    initSocialFeeds: function($content) {
        if ($content.attr('data-twid')) {
            !function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0], p = /^http:/.test(d.location) ? 'http' : 'https';
                if (!d.getElementById(id)) {
                    js = d.createElement(s);
                    js.id = id;
                    js.src = p + "://platform.twitter.com/widgets.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }
            }(document, "script", "twitter-wjs");

        }
        if ($content.attr('data-fburl')) {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v11.0";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
    },

    tabsInit: function () {
        let initSocialFeedsLocal = ($content) => {

            let marketingEnabled = false
            if (Cookiebot !== undefined && Cookiebot.consent !== undefined && Cookiebot.consent.marketing) {
                marketingEnabled = Cookiebot.consent.marketing
            }

            if (marketingEnabled) {
                if ($content.attr('data-twid')) {
                    document.querySelector(".twitter-timeline").classList.remove("hide")
                    !function (d, s, id) {
                        var js, fjs = d.getElementsByTagName(s)[0], p = /^http:/.test(d.location) ? 'http' : 'https';
                        if (!d.getElementById(id)) {
                            js = d.createElement(s);
                            js.id = id;
                            js.src = p + "://platform.twitter.com/widgets.js";
                            fjs.parentNode.insertBefore(js, fjs);
                        }
                    }(document, "script", "twitter-wjs");

                }
                if ($content.attr('data-fburl')) {
                    document.querySelector(".facebook-wrapper").classList.remove("hide")
                    !function (d, s, id) {
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)) return;
                        js = d.createElement(s);
                        js.id = id;
                        js.src = "//connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v11.0";
                        fjs.parentNode.insertBefore(js, fjs);
                    }(document, 'script', 'facebook-jssdk');
                }
            } else {
                document.querySelector(".twitter-timeline").classList.add("hide")
                document.querySelector(".facebook-wrapper").classList.add("hide")
            }
        };

        let tabs = $('#tabs-author, .home-tabs-main, #tabs-blog-sidebar-content');

        if (tabs.length > 0) {

            tabs.find('.tabs-content > .content').on('toggled', function (event, tab) {
                event.preventDefault();
                var tabHash = tab.find('a').attr('href');
                var cleanHash = sukoaUtils.urlGetHashParam(tabHash);
                window.location.hash = "!" + cleanHash;

                if (cleanHash === "social") {
                    if (sukoaDIOG.socialInitialized !== true) {
                        initSocialFeedsLocal($(this));
                        sukoaDIOG.socialInitialized = true;
                    }
                }
            });

            if (tabs.length > 0) {
                var hash = sukoaUtils.urlGetHashParam();
                if (hash) {
                    hash = hash.replace("!", '');
                    // $(window).on('load', function () {
                        $('[href="#' + hash + '"]').trigger('click');
                    // });
                }
            }

            sukoaDIOG.blogTabsSidebar();
        }


    },

    blogTabsSidebar() {
        let $blogSidebar = $('#tabs-blog-sidebar');
        let offset = $blogSidebar.offset();
        let blogListHeight;
        let wh;
        let headerHeight;

        function initvar() {
            blogListHeight = $('.blog-list-column').height();
            wh = $(window).height()-100;
            headerHeight = $('#branding').height() + $('#blog-filter').height();
        }

        initvar();

        $( document ).ajaxComplete(function( event, request, settings ) {
            initvar();
        });

        $(window).scroll(function() {
            if(blogListHeight>wh){
                if($(this).scrollTop() > (offset.top-20)){
                    $blogSidebar.css({position: 'fixed', width: '100%'}).find('#tabs-blog-sidebar-content').addClass("fixed-content medium-push-20");
                    $blogSidebar.find('#tabs-blog-sidebar-content .tabs-content').css({height: ''+wh+''});
                }
                else{
                    $blogSidebar.css({position: ''}).find('#tabs-blog-sidebar-content').removeClass("fixed-content medium-push-20");
                    $blogSidebar.find('#tabs-blog-sidebar-content .tabs-content').css({height: ''});
                }
            } else {
                $blogSidebar.find('#tabs-blog-sidebar-content .tabs-content').css({height: ''+wh-headerHeight-40+'', margin: 0});
            }
        });
    },

    slideshow: function () {
        let slideshows = $('.slick_slideshow');
        if (slideshows.length > 0 && !sukoaUtils.getBaseSetting("isEditMode")) {
            slideshows.each(function (index, element) {
                $(this).slick({
                    slidesToShow: 1,
                    infinite: false,
                    dots: true,
                    adaptiveHeight: true
                });

                $('.slideshow_lightbox.slick_slideshow a.slide').each(function () {
                    $(this).attr('rel', 'lightbox' + index);
                }).fancybox({
                    helpers: {
                        overlay: {
                            locked: false
                        }
                    }
                });
            });
        }
    },



    verifyConsentToMail: function () {
        let verification = true;
        let consentToMailCheckBox = $("#newsletter-consentToMail");
        if (consentToMailCheckBox.length > 0) {
            if (!consentToMailCheckBox.is(":checked")) {
                let selectedNewsletters = $("input[name^='register-newsletter-']:checked");
                if (selectedNewsletters.length > 0) {
                    let feedback = consentToMailCheckBox.attr("data-error-msg");
                    if (!feedback) {
                        feedback = "Please consent to mail to receive our newsletters.";
                    }
                    alert(feedback);
                    consentToMailCheckBox.parent().find("label").addClass("error");
                    verification = false;
                }
            }
        }
        return verification;
    },

    authorTools: function() {
        if(document.body.classList.contains('author')) {
            const bookInfo = document.querySelectorAll('.s-book-info');
            if(bookInfo) {
                for(let i = 0; i < bookInfo.length; i++) {
                    const parent = bookInfo[i].parentNode;

                    const keydownHandler = k => {
                        if(k.shiftKey){
                            if(parent.classList.contains('s-show-info')) {
                                parent.classList.remove('s-show-info')
                            } else {
                                parent.classList.add('s-show-info')
                            }
                        }
                    }
                    const mouseoverHandler = evt => {
                        document.addEventListener('keydown', keydownHandler)
                    }

                    const mouseleaveHandler = evt => {
                        document.removeEventListener('keydown', keydownHandler)
                        // parent.classList.remove('s-show-info');
                    }

                    parent.addEventListener('mouseover', mouseoverHandler)
                    parent.addEventListener('mouseleave', mouseleaveHandler)
                }
            }
        }

    },

    initShoppingCart() {
        new ShoppingCart();
    },

    initSearch() {
        let searchArea = document.querySelector("#search-area")
        let searchPreview = document.querySelector("#search-area .search-preview div")
        let searchForm = document.querySelector("#search-form")
        let searchInput = document.querySelector(".search input.searchbar");
        let searchOverlayClose = document.querySelector("#search-area .close-search");
        let searchFormInput = document.querySelector("#search-input-desktop")
        let toggleFilterButton = document.querySelector(".toggle-filter")

        let filters = document.querySelectorAll(".filters .filter")

        let searchPagination = document.querySelectorAll(".search-pagination")

        searchInput.addEventListener("focus", () => {
            document.body.classList.add("search-open");
            searchFormInput.select()
        })

        searchFormInput.addEventListener("keyup", e => {
            debounce(()=> {

                let searchInput = e.target.value
                if (searchInput.length >= 3) {
                    console.log("Call ajax")
                    $.ajax({
                        url: searchForm.action + "?queryStr=" + searchInput + "&preview=true",
                        method: "POST",
                        success: function (data) {
                            let searchHtml = $(data).find(".row.search .search-books")
                            $(searchPreview).html(searchHtml)
                            sukoaDIOG.fadeInLoadedEntries();
                        }
                    })
                } else {
                    $(searchPreview).html("")
                }
            }, 200)
        })

        searchOverlayClose.addEventListener("click", () => {
            document.body.classList.remove("search-open")
            searchFormInput.value = ''
            $(searchPreview).html("")
        })


        if (toggleFilterButton !== null) {
            toggleFilterButton.addEventListener("click", e => {
                toggleFilterButton.parentElement.parentElement.classList.toggle("filters-open")
                //e.target.parentElement.parentElement.classList.toggle("filters-open")
            })
        }

        filters.forEach(filter => {
            filter.addEventListener("click", e => {
                if (filter.classList.contains("open")) {
                    filter.classList.remove("open")
                } else {
                    document.querySelectorAll(".filters .filter.open").forEach(e => { e.classList.remove("open")})
                    filter.classList.add("open")
                }

            })
        })

        document.querySelectorAll(".filter  input").forEach(it => {
            it.addEventListener("click", e => {
                e.stopPropagation();
            })
        })

        document.querySelectorAll(".filter  input").forEach(it => {
            it.addEventListener("keyup", e => {
                let input = e.target
                let filterValues = input.parentElement.parentElement.querySelectorAll("li label");
                let i = 0;
                filterValues.forEach(label => {
                    if (label.innerText.toLowerCase().indexOf(input.value.toLowerCase()) < 0 || i >= 10) {
                        label.parentElement.classList.add("hide")
                    } else {
                        label.parentElement.classList.remove("hide")
                    }
                    i++
                })
            })
        })

        function updateSearchResult(html, list, target) {
            var parser = new DOMParser();

            // Parse the text
            var doc = parser.parseFromString(html, "text/html");
            let articles = doc.querySelectorAll(".search-container .list-wrapper article")
            articles.forEach(a =>
                list.appendChild(a)
            )
            list.querySelectorAll(".entry.hide").forEach(elem => {
                elem.classList.remove("hide")
            })

            let newMore = doc.querySelector(".search-pagination")
            if (newMore) {
                target.dataset.href = newMore.dataset.href
            } else {
                target.remove()
            }
            sukoaDIOG.fadeInLoadedEntries($(document.querySelectorAll("article .icon")));
        }

        if (searchPagination) {
            searchPagination.forEach(elem => {
                let list = elem.parentElement.querySelector(".list-wrapper");
                elem.addEventListener("click", e => {
                    e.preventDefault();
                    fetch(e.target.dataset.href, {
                        method: 'GET',
                    })
                        .then(response => {
                            return response.text()
                        })
                        .then(html => {
                            let flex = e.target.parentElement.parentElement
                            if (flex && flex.classList.contains("flex")) {
                                flex.classList.add("showmore")
                            }
                            updateSearchResult(html, list, e.target)
                        })
                })
            })
        }


        // filters.forEach(filter => {
        //     filter.addEventListener("mouseenter", e => {
        //         console.log(e)
        //         filter.classList.add("open")
        //     })
        // })
        // filters.forEach(filter => {
        //     filter.addEventListener("mouseleave", e => {
        //         console.log(e)
        //         filter.classList.remove("open")
        //     })
        // })

        let debounceTimer;

        const debounce = (callback, time) => {
            window.clearTimeout(debounceTimer);
            debounceTimer = window.setTimeout(callback, time);
        };

    }
};


let navigation = {
    init: function () {
        navigation.bind();
    },
    bind: function () {
        let body = $('body');
        // fix resize bug
        body.on('click', "[data-menu-open]", function () {
            let t = $(this);
            let target = t.attr('data-menu-open');
            let menu = $(target);
            (t.hasClass("is-active") === true) ? t.removeClass("is-active") : t.addClass("is-active");

            //$('[data-menu-open]').each(function(){
            //    let innerTarget = t.attr('data-menu-open');
            //    $(innerTarget).hide();
            //});

            if (t.hasClass("is-active")) {

                menu.show();
                //$('#mobile-search').hide();
            } else {
                menu.hide();
            }
        });

        body.on('click', '#mobile-search-icon', function() {
            document.body.classList.toggle("search-open");
        })

        let lis = $('#mobile-navigation .main-navigation > ul > li');
        let lastItemClicked;
        body.on('click', '#mobile-navigation .main-navigation > ul > li > a', function (e) {
            let t = $(this);
            let backButton = $('.navigation-button');
            let parent = t.parent('li');
            let children = parent.find('> ul');
            if (children.length > 0) {

                if (lastItemClicked != this) {
                    e.preventDefault();
                }
                backButton.show();
                lis.hide();
                parent.show();
                children.show();
                lastItemClicked = this;
            }
        });

        body.on('click', '.navigation-button', function (e) {
            let backButton = $('.navigation-button');
            backButton.hide();
            lis.show().removeClass('active');
            lis.find('> ul').hide();
        });
    },



};

$(document).ready(function () {
    sukoaDIOG.init();
    $(".home-media .media-icon.youtube").addClass("play-inline");


    if (window.location.hash === '#media-downloads') {

        let fancyboxSettings = {
            autoDimensions: false,
            autoSize: false,
            autoScale: false,
            closeBtn: true,
            autoCenter: true,
            width: 984,
            height: 550,
            transitionIn: 'none',
            transitionOut: 'none',
            scrolling: 'auto',
            wrapCSS: 'panel-fancybox',
            padding: 0,

            afterClose: function () {
                sukoaDIOG.gettingNewPage = false;
            },

            helpers: {
                overlay: {
                    locked: false
                }
            }
        };


        fancyboxSettings.height = "auto";
        fancyboxSettings.width = 400;
        fancyboxSettings.padding = 20;

        fancyboxSettings.closeBtn = true;
        fancyboxSettings.afterLoad = function () {
        };
        fancyboxSettings.afterClose = function () {
            history.pushState("", document.title, window.location.pathname);
        };
        fancyboxSettings.beforeLoad = function () {
            history.pushState(null, null, '#media-downloads');
        };

        // let html = t.parent().find('.downloads-content');

        // if (html.length < 1) {
        let html = $('.downloads-content');
        // }
        $.fancybox(html, fancyboxSettings);

    }


});


// mail decrypt
function dcmadr(nnnn) {
    let a = "";
    for (let i = 0; i < nnnn.length; i++) {
        if (i % 3 === 0) {
            a += String.fromCharCode(nnnn.substr(i, 3));
        }
    }
    location.href = (a);
}

window.dcmadr = dcmadr;

window.onscroll = function() {scrollFunction()};
let lastScrollPosition = 0;

function scrollFunction() {
    let scrollUp = false;
    if (document.documentElement.scrollTop < lastScrollPosition) {
        scrollUp = true;
    }

    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.getElementById("heading").classList.add("scroll");
        // if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200 && !scrollUp) {
        //     document.getElementById("heading").classList.add("compact");
        // } else {
        //     document.getElementById("heading").classList.remove("compact");
        // }
    } else {
        document.getElementById("heading").classList.remove("scroll");
    }
    lastScrollPosition = document.documentElement.scrollTop;
}

export default sukoaDIOG;

