const tools = require('../../tools');
import DataLayerEvent from "../DataLayerEvent";
import ItemWrapper from "../ItemWrapper";

class ViewListDL extends DataLayerEvent {
    static event_name = 'view_item_list';

    constructor(el) {
        super();
        this.listEl = el;
        this.items = [];
        this.name;
        this.id;

        this.init();
    }

    init() {
        if (this.listEl) {
            this.setNameAndId();

            const itemEls = this.listEl.querySelectorAll('.title-icon');
            if (itemEls) {
                const setParameters = item => {
                    const itemWrapper = new ItemWrapper();
                    itemWrapper.setId(item.dataset.ean);
                    itemWrapper.setName(item.dataset.title);
                    this.items.push(itemWrapper);
                };

                tools.each(itemEls, setParameters)
            }
        }
    }

    generateParameters() {
        this.getParameters().event = ViewListDL.event_name;


        this.getParameters().ecommerce = {}

        if (this.name) {
            this.getParameters().ecommerce.item_list_name = this.name;
        }

        if (this.id) {
            this.getParameters().ecommerce.item_list_id = this.id;
        }

        if (this.items.length > 0) {
            this.getParameters().ecommerce.items = this.items.reduce((a, b) => {
                    a.push(b.getItem());
                    return a;
                }, [])
        }
    }

    setNameAndId() {
        this.name = this.listEl.dataset.name;
        this.id = this.listEl.dataset.id;
    }

    matchWith(item = ItemWrapper()) {
        let match = false;
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].matchWith(item)) {
                match = true;
                break;
            }
        }

        return match;
    }

    getName() {
        return this.name;
    }

    getId() {
        return this.id;
    }
}

export default ViewListDL;
