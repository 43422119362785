const $ = require('jquery');
const jQuery = $;
window.$ = window.jQuery = $;

require('jquery-ui/ui/widgets/autocomplete'); //filter search text autocomplete
require('jquery-ui/ui/widgets/draggable'); //make patch draggable
require('jquery-ui/ui/widgets/tabs'); //make patch draggable

require('./vendor/foundation.min');
require('./vendor/foundation.tab');
require('./vendor/slick.min');
require('./vendor/jquery.fancybox.pack');
require('./vendor/jquery.history');
require('./vendor/parsley.min');
require('./vendor/parsley-de');
require('./vendor/parsley-de.extra');
require('./vendor/placeholder');
require('./vendor/jquery.timeago');
require('./vendor/jquery.lazy.min');
