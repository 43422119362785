/*
 * © 2021 Sukoa AG <Interactive Technologies> http://www.sukoa.com
 */

import AddToCartDL from "./datalayer/ecommerce/AddToCartDL";
import tools from "./tools";
import RemoveFromCartDL from "./datalayer/ecommerce/RemoveFromCartDL";
import BeginCheckoutDL from "./datalayer/ecommerce/BeginCheckoutDL";
import ViewCartDL from "./datalayer/ecommerce/ViewCartDL";

const sukoaUtils = require('./vendor/sukoaUtils').sukoaUtils;

function isShoppingCartPage() {
    return document.querySelector(".shoppingCart-component") !== null;
}

function updateData(response) {
    let parentElement = document.getElementById("shoppingCart").querySelector(".shoppingCartData").parentElement;
    let parentElement2 = document.getElementById("shoppingCart").querySelector(".shoppingCartOverlay");

    const newChild = $(response).find(".shoppingCartData")[0];
    const currentChild = document.getElementById("shoppingCart").querySelector(".shoppingCartData");
    if(newChild && currentChild) {
        parentElement.replaceChild(newChild, currentChild);
    }

    const newChild2 = $(response).find(".shoppingCartOverlay .items")[0];
    const oldChild2 = parentElement2.querySelector(".items");
    if (newChild2 && oldChild2) {
        parentElement2.replaceChild(newChild2, oldChild2);
    }
}

class ShoppingCart {
    constructor() {
        let shoppingCartElt = document.getElementById('shoppingCart');

        if (shoppingCartElt) {
            this.shoppingCartElt = shoppingCartElt;
            this.init()
        }
    }

    init() {

        let _this = this;

        $(this.shoppingCartElt).on('mouseenter', ".shoppingCartData", () => {
            if (!sukoaUtils.isMobile() && !isShoppingCartPage()) {
                _this.shoppingCartElt.classList.add("show")
                _this.shoppingCartElt.classList.add("mouse")

                try {
                    let ecommerce = _this.buildCartDataLayer(_this.shoppingCartElt);
                    if(ecommerce) {
                        _this.sendDataLayer(new ViewCartDL(ecommerce))
                    }
                } catch (e) {
                    console.log(e)
                }

            }
        })

        $(this.shoppingCartElt).on('mouseleave', () => {
            if (_this.shoppingCartElt.classList.contains("mouse")) {
                _this.shoppingCartElt.classList.remove("show")
                _this.shoppingCartElt.classList.remove("mouse")
            }
        })

        document.addEventListener('click', event => {
            if (!_this.shoppingCartElt.contains(event.target)) {
                _this.shoppingCartElt.classList.remove("show")
            }
        })


        $('body').on('click', ".add-cart-button:not(.disabled)", function (evt) {
            evt.stopPropagation();
            evt.preventDefault();

            $('.fancybox-close').click()
            let shoppingCartComponent = evt.target.closest('.shoppingCart-component');
            let titleDetailElement = evt.target.closest('.title-detail');

            let loading;
            if (shoppingCartComponent || titleDetailElement) {
                loading = $('body > .shop-overlay-loading');
            } else {
                loading = $('#shoppingCart .shop-overlay-loading');
            }
            loading.fadeIn();
            let url = window.location.origin + window.location.pathname
            //let url = window.location.href
            url = url.replace(window.location.hash, "")
            if (url.indexOf("?") > 0) {
                url += "&"
            } else {
                url += "?"
            }
            url += "cmd=shoppingcart.add&ean=" + evt.currentTarget.dataset.ean + "&quantity=1&ck=" + new Date().getTime()

            $.ajax({
                url: url,
                method: "GET",
                success: function (response) {
                    updateData(response)

                    if (shoppingCartComponent !== null) {
                        shoppingCartComponent.replaceChild($(response).find(".shoppingCart-component .wrapper")[0], shoppingCartComponent.querySelector('.wrapper'))
                    }

                    if ($('#shoppingCart .item[data-ean="' + evt.currentTarget.dataset.ean + '"]').length > 0) {
                        $('.title-icon[data-ean="' + evt.currentTarget.dataset.ean + '"]').addClass('isincart')
                    }

                    if (sukoaUtils.isMobile()) {
                        //Reload added animation
                        loading[0].style.backgroundImage = "url(" + loading[0].dataset.addedAnimation + "?cb=" + Date.now() + ")"
                    } else {
                        if (!_this.shoppingCartElt.classList.contains("show") && shoppingCartComponent === null) {
                            _this.shoppingCartElt.classList.add("show")
                        }
                    }


                    let item = _this.shoppingCartElt.querySelector(".item[data-ean='" + evt.currentTarget.dataset.ean + "']")
                    if (item) {
                        _this.sendDataLayer(new AddToCartDL({
                                currency: 'EUR',
                                value: parseFloat(item.dataset.price),
                                items: [
                                    {
                                        item_name: item.dataset.title,
                                        item_id: item.dataset.ean,
                                        price: parseFloat(item.dataset.price),
                                        quantity: 1
                                    }
                                ]
                            }
                        ))
                    }

                },
                error: function (request, status, error) {
                    if (!_this.shoppingCartElt.classList.contains("show") && shoppingCartComponent === null) {
                        _this.shoppingCartElt.classList.add("show")
                        _this.shoppingCartElt.classList.add("error")
                    }
                },
                complete: function () {
                    if (sukoaUtils.isMobile()) {
                        setTimeout(() => {
                            loading.fadeOut("fast");
                            setTimeout(() => {
                                loading[0].style.backgroundImage = "url(" + loading[0].dataset.loadingAnimation + ")"
                            }, 500)
                        }, 2000)
                    } else {
                        loading.fadeOut("fast");
                    }
                }
            });
        })

        $('body').on('click', ".sub-cart-button:not(.disabled)", function (evt) {
            let shoppingCartComponent = evt.target.closest('.shoppingCart-component');

            let loading;
            if (shoppingCartComponent) {
                loading = $('body > .shop-overlay-loading');
            } else {
                loading = $('#shoppingCart .shop-overlay-loading');
            }
            loading.fadeIn();

            let url = window.location.origin + window.location.pathname
            //let url = window.location.href
            url = url.replace(window.location.hash, "")
            if (url.indexOf("?") > 0) {
                url += "&"
            } else {
                url += "?"
            }
            url += "cmd=shoppingcart.sub&ean=" + evt.currentTarget.dataset.ean + "&quantity=1&ck=" + new Date().getTime()

            $.ajax({
                url: url,
                method: "GET",
                success: function (response) {
                    updateData(response)
                    if (shoppingCartComponent !== null) {
                        shoppingCartComponent.replaceChild($(response).find(".shoppingCart-component .wrapper")[0], shoppingCartComponent.querySelector('.wrapper'))
                    }

                    let item = _this.shoppingCartElt.querySelector(".item[data-ean='" + evt.currentTarget.dataset.ean + "']")

                    _this.sendDataLayer(new RemoveFromCartDL({
                            currency: 'EUR',
                            value: '',
                            items: [
                                {
                                    item_name: item.dataset.title,
                                    item_id: item.dataset.ean,
                                    price: parseFloat(item.dataset.price),
                                    // item_variant: titleDetailElement.dataset.variant,
                                    quantity: 1
                                }
                            ]
                        }
                    ))
                },
                complete: function () {
                    loading.fadeOut("fast");
                }
            });
        })

        $('body').on('click', ".remove-cart-button", function (evt) {
            let shoppingCartComponent = evt.target.closest('.shoppingCart-component');

            let loading;

            if (shoppingCartComponent) {
                loading = $('body > .shop-overlay-loading');
            } else {
                loading = $('#shoppingCart .shop-overlay-loading');
            }
            loading.fadeIn();

            let url = window.location.origin + window.location.pathname
            //let url = window.location.href
            url = url.replace(window.location.hash, "")
            if (url.indexOf("?") > 0) {
                url += "&"
            } else {
                url += "?"
            }
            url += "cmd=shoppingcart.remove&ean=" + evt.currentTarget.dataset.ean + "&quantity=1&ck=" + new Date().getTime()


            $.ajax({
                url: url,
                method: "GET",
                success: function (response) {
                    let oldVal = parseInt(evt.currentTarget.parentElement.querySelector(".input_quantity").value)
                    updateData(response)
                    if (shoppingCartComponent !== null) {
                        shoppingCartComponent.replaceChild($(response).find(".shoppingCart-component .wrapper")[0], shoppingCartComponent.querySelector('.wrapper'))
                    }

                    if ($('#shoppingCart .item[data-ean="' + evt.currentTarget.dataset.ean + '"]').length <= 0) {
                        $('.title-icon[data-ean="' + evt.currentTarget.dataset.ean + '"]').removeClass('isincart')
                    }

                    _this.sendDataLayer(new RemoveFromCartDL({
                            items: [
                                {
                                    item_id: evt.currentTarget.dataset.ean,
                                    quantity: oldVal
                                }
                            ]
                        }
                    ))
                },
                complete: function () {
                    loading.fadeOut("fast");
                }
            });
        })

        $('body').on('click', "#shoppingCart .close", function (evt) {
            _this.shoppingCartElt.classList.remove("show")
            _this.shoppingCartElt.classList.remove("error")
        })


        $('body').on('change', '.input_quantity', function (evt) {
            let shoppingCartComponent = evt.target.closest('.shoppingCart-component');
            let titleDetailElement = evt.target.closest('.title-detail');

            let loading;
            if (shoppingCartComponent || titleDetailElement) {
                loading = $('body > .shop-overlay-loading');
            } else {
                loading = $('#shoppingCart .shop-overlay-loading');
            }
            loading.fadeIn();
            let url = window.location.origin + window.location.pathname
            //let url = window.location.href
            url = url.replace(window.location.hash, "")
            if (url.indexOf("?") > 0) {
                url += "&"
            } else {
                url += "?"
            }

            let ean = evt.target.closest('.item').dataset.ean
            url += "cmd=shoppingcart.set&ean=" + ean + "&quantity=" + evt.target.value + "&ck=" + new Date().getTime()


            $.ajax({
                url: url,
                method: "GET",
                success: function (response) {
                    let oldVal = parseInt(evt.target.dataset.oldVal)

                    updateData(response)

                    if (shoppingCartComponent !== null) {
                        shoppingCartComponent.replaceChild($(response).find(".shoppingCart-component .wrapper")[0], shoppingCartComponent.querySelector('.wrapper'))
                    }

                    if ($('#shoppingCart .item[data-ean="' + evt.currentTarget.dataset.ean + '"]').length > 0) {
                        $('.title-icon[data-ean="' + evt.currentTarget.dataset.ean + '"]').addClass('isincart')
                    }

                    if (sukoaUtils.isMobile()) {
                        //show gif
                        loading[0].style.backgroundImage = "url(" + loading[0].dataset.addedAnimation + "?cb=" + Date.now() + ")"
                        // setTimeout(() => {
                        //     loading.fadeOut("fast");
                        //     loading[0].style.backgroundImage= "url("+loading[0].dataset.loadingAnimation+")"
                        // } , 2000)
                    } else {
                        if (!_this.shoppingCartElt.classList.contains("show") && shoppingCartComponent === null) {
                            _this.shoppingCartElt.classList.add("show")
                        }
                    }

                    let item = _this.shoppingCartElt.querySelector(".item[data-ean='" + ean + "']");
                    let dl_quantity = parseInt(_this.shoppingCartElt.querySelector(".item[data-ean='" + ean + "'] input").value)

                    let diff = dl_quantity - oldVal
                    console.log(diff)

                    if (diff < 0) {
                        _this.sendDataLayer(new RemoveFromCartDL({
                                currency: 'EUR',
                                value: parseFloat(item.dataset.price) * diff * -1,
                                items: [
                                    {
                                        item_name: item.dataset.title,
                                        item_id: item.dataset.ean,
                                        price: parseFloat(item.dataset.price),
                                        // item_variant: titleDetailElement.dataset.variant,
                                        quantity: diff * -1
                                    }
                                ]
                            }
                        ))
                    } else {
                        _this.sendDataLayer(new AddToCartDL({
                                currency: 'EUR',
                                value: parseFloat(item.dataset.price) * diff,
                                items: [
                                    {
                                        item_name: item.dataset.title,
                                        item_id: item.dataset.ean,
                                        price: parseFloat(item.dataset.price),
                                        // item_variant: titleDetailElement.dataset.variant,
                                        quantity: diff
                                    }
                                ]
                            }
                        ))
                    }

                },
                complete: function () {
                    if (sukoaUtils.isMobile()) {
                        setTimeout(() => {
                            loading.fadeOut("fast");
                            setTimeout(() => {
                                loading[0].style.backgroundImage = "url(" + loading[0].dataset.loadingAnimation + ")"
                            }, 500)
                        }, 4000)
                    } else {
                        loading.fadeOut("fast");
                    }
                }
            });
        });

        // $('body').on('click', '.checkout-button a', function (evt) {
        //     try {
        //         let ecommerce = _this.buildCartDataLayer(_this.shoppingCartElt);
        //         _this.sendDataLayer(new BeginCheckoutDL(ecommerce))
        //     } catch (e) {
        //         console.log(e)
        //     }
        // })
        //
        // $('body').on('click', 'a .checkout-button', function (evt) {
        //     try {
        //         let ecommerce = _this.buildCartDataLayer(_this.shoppingCartElt);
        //         _this.sendDataLayer(new BeginCheckoutDL(ecommerce))
        //     } catch (e) {
        //         console.log(e)
        //     }
        // })


        document.addEventListener("focusin", (evt) => {
            if (evt.target.classList.contains("input_quantity")) {
                evt.target.dataset.oldVal = evt.target.value;
            }
        })


        let url = window.location.origin + window.location.pathname
        //let url = window.location.href.split('?')[0]
        url = url.replace(window.location.hash, "")
        if (url.indexOf("?") > 0) {
            url += "&"
        } else {
            url += "?"
        }
        url += "cmd=shoppingcart.count&ck=" + new Date().getTime()

        fetch(url, {
            method: 'GET',
        })

            .then(response => {
                return response.text()
            })
            .then(text => {
                updateData(text)
                tools.each(document.querySelectorAll(".title-icon.isincart"), e => {
                    e.classList.remove('isincart')
                })

                tools.each(document.querySelectorAll(".shoppingCartOverlay .item[data-ean]"),
                    element => {
                        let ean = element.dataset.ean;
                        let book = document.querySelector('.title-icon[data-ean="' + ean + '"]');
                        if (book) {
                            book.classList.add('isincart')
                        }
                    }
                )
            })
    }

    sendDataLayer(datalayerEvent) {
        tools.executeDataLayerBy('default', datalayerEvent, new Event("DLEvent"));
    }

    buildCartDataLayer(shoppingCartElt) {
        if (shoppingCartElt) {
            const item = shoppingCartElt.querySelector(".item[data-price-total]");
            if (item) {
                const ecommerce = {
                    currency: 'EUR',
                    value: parseFloat(item.dataset.priceTotal),
                    items: []
                }

                shoppingCartElt.querySelectorAll(".item[data-ean]").forEach(item => {
                    let shoppingCartItem = {
                        item_name: item.dataset.title,
                        item_id: item.dataset.ean,
                        price: parseFloat(item.dataset.price),
                        // item_variant: titleDetailElement.dataset.variant,
                        quantity: parseFloat(item.querySelector("input.input_quantity").value)
                    };

                    ecommerce.items.push(shoppingCartItem)
                })
                return ecommerce
            }
        }
    }


}

export default ShoppingCart;
