const $ = require('jquery');
const tools = require('./tools');
const sukoaUtils = require('./vendor/sukoaUtils').sukoaUtils;

class Form {

    //types: newsletterSubscription, commentForm, none
    constructor($elt, type='') {
        this.$elt = $elt;
        this.type = type;
        this.$submitBtn = null;
        if(!type && this.$elt.attr('id') === 'newsletter-form') {
            this.type = 'newsletterSubscription';
        }

        this.init();
    }

    init() {

        this.$submitBtn = this.$elt.find('input[type=submit]');
        this.initCounters();
        let checkFct = () => this.checkInputs();

        if(this.type === 'newsletterSubscription') {
            checkFct = () => this.checkNLInputs();
        } else if(this.type === 'commentForm') {
            checkFct = () => this.checkCommentInputs();
        }

        this.$elt.submit(evt => {
            let recaptcha = this.$elt[0].dataset.recaptcha
            let recaptchaEnabled = recaptcha !== undefined
            let grTokenField = this.$elt[0].querySelector("[name='grToken']");
            if (recaptchaEnabled && (grTokenField == null || grTokenField.value === '')) {
                evt.preventDefault();
            }

            this.$submitBtn.attr('disabled', 'disabled');
            if(!checkFct()) {
                evt.preventDefault();
                this.$submitBtn.removeAttr('disabled');
            } else {
                if (recaptchaEnabled) {
                    let _form = this.$elt
                    let action = this.$elt[0].dataset.recaptchaAction
                    if (!action) {
                        action = 'submit'
                    }
                    if (grTokenField == null || grTokenField.value === '') {
                        grecaptcha.ready(function () {
                            grecaptcha.execute(recaptcha, {action: action}).then(function (token) {
                                // Add your logic to submit to your backend server here.
                                let input = document.createElement("input");
                                input.setAttribute("type", "hidden");
                                input.setAttribute("name", "grToken");
                                input.setAttribute("value", token);
                                _form[0].appendChild(input);
                                _form.trigger("submit");
                            }).catch(function (error) {
                                console.warn("Error " + error)
                            });
                        });
                    }
                    this.$submitBtn.removeAttr('disabled');
                }
            }
        });

        this.acceptPrivacyStmt()
    }

    acceptPrivacyStmt() {
        let accept = this.$elt.find('#accept-privacy-statement');
        let submit = this.$elt.find('#accept-privacy-submit');
        if(accept.length > 0 && submit.length > 0) {
            accept.change(() => accept.is(':checked') ? submit.removeAttr('disabled') : submit.attr('disabled','disabled'))
        }
    }

    initCounters() {
        let updateCharCount = (charcounter,input, maxChar) => {
            let textLength = input.val().length;
            charcounter.text(textLength);
            return textLength <= maxChar;
        };

        this.$elt.find('.charcounter').each(function () {
            let t = $(this);
            let $fieldParent = t.parent().parent();
            let input = $fieldParent.find("input");
            let maxChar = $fieldParent.attr('data-maxchars') * 1;
            if (input.length < 1) {
                input = t.parent().parent().find("textarea");
            }

            input.keypress(function () {
                if(!updateCharCount(t, input, maxChar)) {
                    $fieldParent.addClass('error');
                } else if($fieldParent.hasClass('error')) {
                    $fieldParent.removeClass('error')
                }
            });
            input.keyup(function () {
                if(!updateCharCount(t, input, maxChar)) {
                    $fieldParent.addClass('error');
                } else if($fieldParent.hasClass('error')) {
                    $fieldParent.removeClass('error')
                }
            });
            input.change(function () {
                if(!updateCharCount(t, input, maxChar)) {
                    $fieldParent.addClass('error');
                } else if($fieldParent.hasClass('error')) {
                    $fieldParent.removeClass('error')
                }
            });
        });

    }

    checkCommentInputs() {
        let f = this.$elt;
        f.find('.error').removeClass('error');
        let result = true;
        f.find(":text.required, input:file.required, input:radio.required, textarea.required").each(function () {
            if ($(this).val().trim() === "") {
                handleValidationError(this, $(this).attr("data-errormessage"));
                result = false;
            }
        });
        f.find("select.required").each(function () {
            if ($(this).prop("selectedIndex") === 0) {
                handleValidationError(this, $(this).attr("data-errormessage"));
                result = false;
            }
        });
        f.find(":text.email").each(function () {
            let c = $(this).val().trim();
            if (c !== "" && !validateEmail(c)) {
                handleValidationError(this, $(this).attr("data-errormessage"));
                result = false;
            }
        });
        f.find('.optiongroup').each(function(){
            if($(this).parent().hasClass("required")){
                if ( $(this).find('input:radio,input:checkbox').length>0 && $(this).find('input:radio:checked,input:checkbox:checked,.custom.radio.checked').length ===0  ) {
                    handleValidationError(this, $(this).attr("data-errormessage"));
                    result = false
                }
            }
        });

        if(result) {
            let commentWrapperOuter = $("#comment-wrapper-outer");
            commentWrapperOuter.on("submit","form",function(e){
                let theForm = $(this);
                let url = theForm.attr("action");
                if(url===""){url = sukoaUtils.getCurrentURL(true);}
                $.ajax({
                    type: "POST",
                    url: url,
                    data: theForm.serialize(),
                    success: function(data) {
                        theForm.slideUp();
                        $("#comment-sent-confirm").show();
                    }
                });
                e.preventDefault();
            });

        }
        function handleValidationError(obj, message) {
            $(obj).addClass("error");
            if ( $('.error').length<=1) {
                alert(getJsDecodedString(message));
            }
            return false;
        }
        function validateEmail(email) {
            let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
        /* prevent &Uuml;mlauts, etc in js alerts.*/
        function getJsDecodedString(raw) {
            let div = document.createElement('div');
            div.innerHTML = raw;
            let decoded = div.firstChild.nodeValue;
            return decoded;
        }

        return result;
    }

    checkInputs() {

        this.$elt.find('.error').removeClass('error');
        let thisForm = this;
        let result = true;
        this.$elt.find(".emptybasketwarning").each(function () {
            alert($(this).text());
            result = false;
        });
        this.$elt.find("[data-maxchars]").each(function () {
            let t = $(this);
            let v = t.attr("data-maxchars");
            let input = t.find("input");
            if (input.length < 1) {
                input = t.find("textarea");
            }
            let textlength = input.val().length;
            if (textlength > v) {
                let feedback = thisForm.$elt.attr('data-toomanychars');
                Form.handleValidationError(this, feedback.replace("{maxchars}", v));
                result = false;
            }
        });
        this.$elt.find(":text.required, [type=email].required, input:file.required, input:radio.required, textarea.required").filter(':visible').each(function () {
            if ($(this).val().trim() === "") {
                Form.handleValidationError(this, thisForm.$elt.attr('data-mandatoryfield'));
                result = false;
            }
        });
        this.$elt.find("select.required").filter(':visible').each(function () {
            if ($(this).prop("selectedIndex") === 0) {
                Form.handleValidationError(this, thisForm.$elt.attr('data-mandatoryfield'));
                result = false;
            }
        });
        this.$elt.find(":text.email").filter(':visible').each(function () {
            let c = $(this).val().trim();
            if (c !== "" && !Form.validateEmail(c)) {
                Form.handleValidationError(this, thisForm.$elt.attr('data-emailformat'));
                result = false;
            }
        });

        this.$elt.find('.conditionalShow.required').filter(':visible').each(function () {
            let group = $(this).find('input:checkbox').attr('data-conditional-show-group');
            let groupInputs = $('[data-conditional-show-group="' + group + '"]');
            let groupInputsChecked = groupInputs.filter(":checked");
            if (groupInputs.length > 0 && groupInputsChecked.length === 0) {
                Form.handleValidationError(this, thisForm.$elt.attr('data-mandatoryfield'));
                result = false
            }
        });

        this.$elt.find('.optiongroup').filter(':visible').each(function () {
            if ($(this).parent().hasClass("required")) {
                if ($(this).find('input:radio,input:checkbox').length > 0 && $(this).find('input:radio:checked,input:checkbox:checked,.custom.radio.checked').length === 0) {
                    Form.handleValidationError(this, thisForm.$elt.attr('data-mandatoryfield'));
                    result = false
                }
            }
        });

        let newsletterSubscriptionComponent = this.$elt.find('.newsletterSignup');
        if(newsletterSubscriptionComponent.length > 0) {
            let elem = newsletterSubscriptionComponent.find('.consentToMail');
            if(elem.length > 0 && (!elem.is(":checked") && !elem.hasClass("not-mandatory"))){
                Form.handleValidationError(elem.parent(), newsletterSubscriptionComponent.attr('data-noconsenttomail'));
                // alert(thisForm.$elt.attr('data-noconsenttomail'));
                elem.parent().find("label").css("color","#e80000");
                result =  false;
            }
        }

        if (result) {
            $(".form-element:hidden").remove();
        }
        return result;
    }

    checkNLInputs() {

        let thisForm = this;
        if ($("input[name=newsletter]:checked:visible").length === 0 && $("input[name=newsletter]:hidden").length === 0 ) {
            alert(thisForm.$elt.attr('data-noselection'));
            return false;
        }

        let nlsub = $("#newsletter-email");

        function checkEmail(elem) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let corr = re.test(elem.val());
            if(!corr){
                alert(thisForm.$elt.attr('data-noemail'));
                $(elem).addClass('error');
                elem.focus();
                return false;
            }
            return checkConsentToMail();
        }

        function checkConsentToMail() {
            let elem = $("#newsletter-consentToMail");
            if(elem.length > 0 && !elem.is(":checked")){
                alert(thisForm.$elt.attr('data-noconsenttomail'));
                elem.parent().find("label").css("color","#e80000");
                return false;
            } else {
                return true;
            }
        }

        return checkEmail(nlsub);
    }

    static getJsDecodedString(raw) {
        let div = document.createElement('div');
        div.innerHTML = raw;
        return div.firstChild.nodeValue;
    }

    static handleValidationError(obj, message) {
        $(obj).addClass("error");
        if ($('.error').length <= 1) {
            alert(Form.getJsDecodedString(message));
        }
        return false;
    }


    static validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }


}

let data = [
    "Creo ", "en los milagros", "d", "esde que te vi",
    "En es", "ta noche de", " tequ", "ila boom, boom.",
    "Eres ", "tan sexy,", " eres s", "exy thing",
    "Mis o", "jos te persi", "guen", " solo a ti.",
    "Y deb", "e haber un ca", "os ", "dentro de ti",
    "Para ", "que brotes de ", "un", "a estrella que baila.",
    "Infie", "rno y para", "iso de", "ntro de ti,",
    "La lu", "na es un ", "sol mir", "a como brilla.",
    "Baby ", "the night", " is on ", "fire",
    "Seamo", "s fuego ", "en el ci", "elo,",
    "Llama", "s en lo obsc", "uro,", " what you say",
    "Baila", ", bail", "a morena",", ",
    "Bajo ", "de esta", " luna lle", "na,",
    "Under", " the ", "moonlight",", ",
    "Under", " the ", "moonlight",", ",
    "Ven c", "hica, ven", " loca, ", "dame tu boca,",
    "En es", "ta noche", " cualqui", "er cosa te toca.",
    "Mi co", "razon se ", "revient", "a y no aguanto,",
    "Moren", "a rebuena", " te qui", "ero yo tanto.",
    "Baby,", " the nigh", "t is on", " fire",
    "Seamo", "s fuego e", "n el ci", "elo,",
    "Escan", "dalo en l", "o obscu", "ro, what you say",
    "¨Bail", "a, baila ", "morena, ",
    "Bajo ", "de esta l", "una lle", "na",
    "Under", " the moon", "light",", ",
    "Y bai", "la, under", " the mo", "onlight",
    "Bajo ", "de esta l", "una lle", "na",
    "Baila", " morena",", ",", ",
    "Yeah,", " yeah, ye", "ah,",", ",
    "You g", "ot me hut", "in' so ", "bad, so bad",
    "You l", "et me so,", " so bad", "",
    "What ", "you say..", ".",", ",
    "Baila", ", baila m", "orena",", ",
    "Bajo ", "de esta l", "una lle", "na",
    "Under", " the moon", "light (", "under the moonlight)",
    "Y bai", "la (baila", "), unde", "r the moonlight,",
    "Bajo ", "de esta l", "una lle", "na (baila morena)",
    "Baila", " morena (", "come on", " baby, come on)",
    "Bajo ", "de esta l", "una lle", "na,",
    "Bajo ", "de esta l", "una lle", "na,",
    "Bajo ", "de esta l", "una lle", "na",
    "Under", " the moon", "light"
];


function fillform () {
    let param = tools.getParameterByName("fillform");
    if(param === "true") {
        $('input[type="checkbox"]').attr("checked", "checked");
        let $form = $(".form-element");
        $form.each(function() {
            let radiobuttons = $(this).find('input[type="radio"]');
            if(radiobuttons.length > 0) {
                $(radiobuttons[Math.round(Math.random() * (radiobuttons.length - 1))]).attr("checked", "checked");
            }

            let select = $(this).find("select");
            if(select.length > 0) {
                select.each(function() {
                    let options = $(this).find("option");
                    if(options.length > 1) {
                        $(this).val($(options[Math.round(Math.random() * (options.length - 2) + 1)]).val());
                    }

                })
            }

        });
        // text inputs
        let index = 0;
        $form.find('input[type="text"], textarea').each(function(){
            if(data.length > index) {
                $(this).val(data[index]);
                index++;
            }
        });
        $form.find('input[type="email"]').each(function(){

            $(this).val("jm@sukoa.com");

        });
    }

}

$(document).ready(function() {
    fillform();
});


export default Form;
