import ViewItemDL from "./ViewItemDL";

class SelectItemDL extends ViewItemDL {
    static event_name = "select_item";

    constructor(el) {
        super(el);
    }

    generateParameters() {
        super.generateParameters();
        this.getParameters().event = SelectItemDL.event_name;
    }
}

export default SelectItemDL;